import Input from "../ui/form/Input";
import React, {useState} from "react";
import LoadingSpinner from "../common/loading/LoadingSpinner";
import {deleteEntity} from "../../api/estimations";
import Button from "../ui/form/Button";
import {Delete} from "@material-ui/icons";
import {Confirm} from "../ui/Confirm";
import {useTranslation} from "react-i18next";
import handleEstimationChange from "../estimation/private/handleEstimationChange";
import {flashMessage} from "../../utils/flashes";
import {env} from "../../utils/helpers";

const MAX_RATE_VALUE = 5000;
const debug = env('DEBUG');

export default function RoleDetail(props) {

    const {t} = useTranslation();
    const [role, setRole] = useState(props.role);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string | undefined>();
    const {focus} = props;
    const onChange = (newRole) => {
        props.onChange(props.role, newRole);
        setRole(newRole);
    }

    const handleChange = (what: string, value: number) => {
        if (value > MAX_RATE_VALUE) {
            flashMessage(t('Rate is too high'), 'warning');
            value = MAX_RATE_VALUE;
        }
        handleEstimationChange({
            key: 'role',
            item: role,
            what,
            value,
            setFn: onChange,
            loadingFn: setLoading,
            setBeforePost: false
        })
    }
    const handleDelete = async () => {
        setLoading(true);
        try {
            await deleteEntity('role', role.id, false);
            props.onDelete(role);
        } catch (e) {
            setMessage(t('Unable to delete role'));
            setTimeout(() => setMessage(undefined), 2000);
        } finally {
            setLoading(false);
        }
    }
    return <div className="flex relative items-center gap-1">
        {debug && <small className="text-gray-400">#{role.id}</small>}
        <Input className={"w-9/12"} placeholder={t("Role name")} defaultValue={role.name}
               name={"name"} spellCheck="false" onChange={handleChange} autoFocus={focus} />
        <Input className={"w-3/12"} placeholder={t("Role rate")} defaultValue={role.rate} type="number" max={5000} /*2 bytes*/ min={0}
               name={"rate"} spellCheck="false" onChange={handleChange}/>
        <Confirm message={t("Re u sure bro? No way back")} onSuccess={handleDelete}>
            <Button icon={Delete} color={'red'}/>
        </Confirm>
        {message &&
        <Overlay className={"text-white bg-red-500 "}>
            {message}
        </Overlay>}
        {loading &&
        <Overlay className={"text-white bg-white bg-opacity-50 "}>
            <LoadingSpinner className={"text-primary"}/>
        </Overlay>}
    </div>
}

const Overlay = (props) => <div className={'absolute left-0 top-0 right-0 bottom-0 flex items-center justify-center '+(props.className||'')}>
    {props.children}
</div>;
