import React, {useState} from 'react';
import Input from "../ui/form/Input";
import Button from "../ui/form/Button";
import Paper from "../common/Paper";
import {signIn} from "../../api/sign";
import {login} from "../user";
import {flashMessage} from "../../utils/flashes";
import Password from "../ui/form/Password";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {IUser} from "../user/userSlice";
import {ApiError} from "../../api/common";
import clsx from "clsx";
import CountDown from "../ui/CountDown";

type LoginState = {
    email?: string,
    password?: string,
    error?: string | false
    showPassword?: boolean,
    invalidField: string | null,
    ban: Date | null
};

const Login = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const [state, setState] = useState<LoginState>({
        email: '',
        password: '',
        error: false,
        showPassword: false,
        invalidField: null,
        ban: null
    });

    const validate = () => {
        if (!state.email || !state.password) {
            flashMessage(t('Please fill all required inputs.'), 'warning')
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validate()) return;
        setState({
            ...state,
            error: null,
            invalidField: null,
            ban: null,
        });
        signIn({email: state.email, password: state.password})
            .then((data: IUser) => {
                login(data);
                history.push('/');
            })
            .catch((err) => {
                if (err instanceof ApiError) {
                    if (err.responseBody.field) {
                        handleChange('invalidField', err.responseBody.field);
                    }
                    if (err.responseBody.message) {
                        handleChange('error', err.responseBody.message);
                    }
                    if (err.responseBody.ban) {
                        handleChange('ban', new Date(err.responseBody.ban));
                    }
                }
                flashMessage(t(err.message), 'error');
            });
    };

    const handleChange = (what: keyof LoginState, value) => {
        //console.log({[what]: value});
        setState({...state, [what]: value});
    }

    const banMills = (): number => {
        return Math.ceil((state.ban.getTime() - (new Date()).getTime()) / 1000);
    }

    return (
        <Paper className={"max-w-lg mx-auto"} header={<h1 className={"text-2xl"}>{t('Login')}</h1>}>
            <form onSubmit={handleSubmit}>
                <Input key="email" name="email" type="email" placeholder={t('E-mail')}
                       className={clsx("w-full px-3", state.invalidField === 'email' ? 'border-rose-600' : null)}
                       required={true}
                       autoComplete="username"
                       value={state["email"]}
                       onChange={handleChange}
                />
                <div className={"relative my-3"}>
                    <Password
                        className={state.invalidField === 'password' ? 'border-rose-600' : null}
                        required={true}
                        name={'password'}
                        placeholder={t('Password')}
                        onChange={handleChange}
                        defaultValue={state.password}
                    />
                </div>
                <Button bg={'primary'} color={'white'} type="submit" className={"w-full"}>
                    {t('Log in')}
                </Button>
            </form>
            {state.ban && <CountDown duration={banMills()} onComplete={() => handleChange('ban', null)}/>}
        </Paper>
    );
};

export default Login;
