const prefix = 'REACT_APP_';
export const parseEnv = (values: Record<string, string>): Env => {
    let ret = {};
    for (let [k, v] of Object.entries(values)) {
        if (k.startsWith(prefix)) {
            k = k.substring(prefix.length);
            ret[k] = isNaN(Number(v)) ? parseVal(v) : parseInt(v);
        }
    }
    return ret as Env;
}
const parseVal = (value: string) => {
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
};
export const env: Env = parseEnv(process?.env || {});
export type Env = {
    VERSION: string
    NAME: string
    API_POINT_URL: string
    UPDATE_DEBOUNCE_DELAY: number
    TRANSITION_DURATION: number
    FLASH_MESSAGE_TIMEOUT: number
    AUTHORS: string
    DEBUG?: boolean
}
export default env;