import React from "react";
import {useTranslation} from "react-i18next";
import {EstimationMetrics} from "../private/EstimationEditorRow";
import Paper from "../../common/Paper";
import clsx from "clsx";

function calculate(estimation) {
    const prices = {};
    let hasPrices = false;
    for (let block of estimation.blocks) {
        for (let row of block.rows) {
            if (prices[row.role.id] === undefined) {
                prices[row.role.id] = {amount: 0,price: 0};
            }
            if (row.metrics === EstimationMetrics.hours && row.amount && row.role.rate) {
                prices[row.role.id].price += row.amount * row.role.rate;
                prices[row.role.id].amount += row.amount;
                hasPrices = true;
            }
        }
    }
    return hasPrices && prices;
}

const Row = ({cols, className = null}) => (
    <div className={clsx("grid grid-cols-12 gap-x-4 print:mb-0 hover:bg-gray-100 transition-colors", className)}>
        {cols.map((col, i) => (
            <div className={clsx(
                "col-span-full",
                i === 0 ? "md:col-span-6 print:col-span-6" : "md:col-span-2 print:col-span-2",
                i === (cols.length - 1) && "text-right",
            )}>{col}</div>
        ))}
    </div>
);
export default function EstimationViewLegend({estimation}) {
    const {t} = useTranslation();
    const prices = calculate(estimation);
    return prices && (
        <div className={"break-avoid py-3"}>
            <Paper className={"relative print:border print:border-gray-100"} header={<h2 className="mb-0 text-xl">{t('legend.title')}</h2>}>
                <Row className={"mb-2 font-bold"} cols={[t('legend.name'), t('legend.rate'), t('legend.amount'), t('legend.price')]}/>
                {estimation.roles?.map((role: any) =>
                    <EstimationViewLegendItem prices={prices} role={role} />
                )}
            </Paper>
        </div>
    );
}
export function EstimationViewLegendItem({role,prices}) {
    const {t} = useTranslation();
    return Boolean(prices[role.id]?.amount) && (
        <Row key={role.id} cols={[role.name, role.rate, prices[role.id].amount || 0, t('money', {value: prices[role.id].price || 0})]}/>
    );
}