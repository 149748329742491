import {updateMultiple} from "../../../api/estimations";
import {BlockData, RowData} from "../../../api/types";
import {DropResult} from "react-beautiful-dnd";

export const DND_TYPE_BLOCK = 'block';
export const DND_TYPE_ROW = 'row';

export const handleDnD = {
    [DND_TYPE_ROW]: handleRowDnD,
    [DND_TYPE_BLOCK]: handleBlockDnD
};

export function handleBlockDnD (blocks: BlockData[], {destination, source, draggableId}: DropResult) {
    if (!destination) return blocks;
    const _blocks = [...blocks];
    const [, block] = getBlockInfo(blocks, parseInt(draggableId));
    _blocks.splice(source.index, 1);
    _blocks.splice(destination.index, 0, block);
    const data = [..._blocks.map((row, idx) => ({id: row.id, index: idx}))];
    if (data.length) {
        updateMultiple<BlockData>('block', data)
            .then((result) => {
                console.log(result);
            }).catch((err)=>{
                console.log(err);
        });
    }
    return _blocks;
}

export function handleRowDnD(blocks: BlockData[], {destination, source, draggableId}: DropResult): BlockData[] {
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index))
        return blocks;

    const destinationId = destination.droppableId;
    const sourceId = source.droppableId;
    const sameSource = destinationId === source.droppableId;
    const _blocks = [...blocks];

    const data = [];
    if (!sameSource) {
        let [sourceBlockIndex, sourceBlock, sourceRows] = getBlockInfo(blocks, sourceId);
        let [destinationBlockIndex, destinationBlock, destinationRows] = getBlockInfo(blocks, destinationId);
        const row = {...sourceRows.find(row => row.id === parseInt(draggableId))}
        sourceRows.splice(source.index, 1);
        destinationRows.splice(destination.index, 0, row);
        _blocks[sourceBlockIndex] = {...sourceBlock, rows: sourceRows};
        _blocks[destinationBlockIndex] = {...destinationBlock, rows: destinationRows};
        data.push(
            ...sourceRows.map((row, idx) => ({id: row.id, index: idx, block_id: sourceBlock.id})),
            ...destinationRows.map((row, idx) => ({id: row.id, index: idx, block_id: destinationBlock.id}))
        );
    }
    if (sameSource) {
        const [blockIndex, block, rows] = getBlockInfo(_blocks, destinationId);
        console.log({blockIndex, block, rows});
        const row = {...block.rows.find(row => row.id === parseInt(draggableId))}
        rows.splice(source.index, 1);
        rows.splice(destination.index, 0, row);
        _blocks[blockIndex] = block;
        data.push(
            ...rows.map((row, idx) => ({id: row.id, index: idx}))
        );
    }
    if (data.length) {
        updateMultiple<RowData>('row', data)
            .then((result) => {
                console.log(result);
            }).catch((err)=>{
            console.log(err);
        });
    }
    return _blocks;
}

const getBlockInfo = (blocks, id): [number, BlockData, RowData[]] => {
    const blockIndex = blocks.findIndex(block => block.id === parseInt(id))
    return [blockIndex, {...blocks[blockIndex]}, {...blocks[blockIndex]}.rows]
}
