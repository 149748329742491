import store from "../store";
import {IUser, userSlice} from "./userSlice";

export const login = (user: IUser) => {
    store.dispatch(userSlice.actions.login(user))
}
export const logout = () => {
    store.dispatch(userSlice.actions.logout())
}
export const update = (data: IUser) => {
    store.dispatch(userSlice.actions.update(data))
}
