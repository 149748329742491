import React, {useEffect, useState} from "react";
import RoleDetail from "../roles/RoleDetail";
import Button from "../ui/form/Button";
import LoadingSpinner from "../common/loading/LoadingSpinner";
import {Add} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {RoleData} from "../../api/types";
import {postChange} from "../../api/estimations";

export default function RolesList(props) {
    const {roles} = props;
    const {t} = useTranslation();
    const [rolesState, setRoles] = useState<RoleData[]>([]);
    const [addRoleLoading, setAddRoleLoading] = useState(false);

    useEffect(() => {
        const loadRoles = async () => {
            setRoles(await roles());
        }
        loadRoles();
    })

    const handleCreateRole = async () => {
        setAddRoleLoading(true);
        const newRole = await postChange<RoleData>("role", {estimation_id: null});
        setRoles([...rolesState, newRole]);
        setAddRoleLoading(false);
    }

    const onChange = async (role?: RoleData, newRole?: RoleData) => {
        /*roles[roles.indexOf(role)] = newRole;
        const newRoles = [...roles];
        setRoles(newRoles);
        props.onChange(newRoles);*/
    };

    const onDelete = async (role: RoleData) => {
        setRoles(rolesState.filter(r => r !== role));
    };
    return (
        <div>
            {rolesState.map(role =>
                <RoleDetail onDelete={onDelete} onChange={onChange} key={role.id} role={role}/>)
            }
            <div className={"text-center mt-8"}>
                <Button bg={'primary'} className={"w-full uppercase"} onClick={handleCreateRole}>
                    {addRoleLoading ? <LoadingSpinner className={"inline"}/> : <Add/>}
                    <span className={"ml-2"}>{t('create new role')}</span>
                </Button>
            </div>
        </div>
    );
}
