import React, {cloneElement, useRef, useState} from "react";
import {Check, Close, WarningOutlined} from "@material-ui/icons";
import clsx from "clsx";
import {env} from "../../utils/helpers";
import {CSSTransition} from "react-transition-group";
import {ClickAwayListener} from "@material-ui/core";

type ConfirmType = {
    approve: () => any,
    close: () => any,
}

let confirm: ConfirmType = null;
window.addEventListener('keyup', (e) => {
    if (confirm) {
        if (e.key === 'Escape') {
            confirm.close();
        }
    }
});

export const Confirm = (props) => {
    const {message, onSuccess, children, className, messageClassName, relative = false, allowForce = true} = props;
    const [opened, setOpened] = useState<boolean>(false);
    const yesRef = useRef<HTMLButtonElement>(null);
    const focus = () => {
        yesRef.current?.focus();
    }
    const close = () => {
        confirm = null;
        setOpened(false);
    };
    const approve = () => {
        onSuccess();
        close();
    };
    const open = (e) => {
        if (confirm) confirm.close();
        if (allowForce && e.shiftKey) {
            approve();
            return;
        }
        confirm = {approve, close};
        setOpened(true);
    };

    const newChildren = cloneElement(children, {tabIndex: opened ? -1 : null, onClick: open});
    return (
        <div className={clsx("flex items-center", className, relative && 'relative')} title={props.title}>
            {newChildren}
            <div
                 className={clsx('absolute z-0 right-0 rounded   drop-shadow-md', messageClassName, relative || 'overflow-hidden', opened || 'pointer-events-none')}>
                <CSSTransition in={opened} unmountOnExit timeout={env('TRANSITION_DURATION')} classNames="transition"
                               onEntered={focus}>
                    <ClickAwayListener onClickAway={close}>
                    <div
                         className={clsx(`bg-gradient-to-r from-red-600 to-red-700 flex items-center justify-end `, relative || 'm-2 ')}>
                        <div className="flex items-center text-gray-200 w-full px-2 whitespace-nowrap">
                            <WarningOutlined fontSize={"small"} className={"mr-2"}/>
                            {message}
                        </div>
                        <div className={"flex bg-white"}>
                            <button ref={yesRef} tabIndex={opened ? null : -1} title={"Yes"}
                                    className={'p-1 relative focus:outline-black bg-green-500 text-white hover:bg-green-400 focus:z-10'}
                                    onClick={approve}>
                                <Check/>
                            </button>
                            <button tabIndex={opened ? null : -1} title={"No"}
                                    className={'p-1 relative focus:outline-black bg-gray-500 text-white hover:bg-gray-400 focus:z-10'}
                                    onClick={close}>
                                <Close/>
                            </button>
                        </div>
                    </div>
                    </ClickAwayListener>
                </CSSTransition>

            </div>
        </div>
    );
};
