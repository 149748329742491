import {EstimationData} from "../api/types";
import ExcelJS from "exceljs"
import {saveAs} from 'file-saver'
import {blockPrice} from "./priceHelpers";
import {EstimationMetrics} from "../components/estimation/private/EstimationEditorRow";
import i18n from "i18next"

const defaultFont = {name: 'Arial', family: 2, size: 10}
const currencyFormatter = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK' })

export async function ExportEstimation(estimation: EstimationData) {
    console.log("exporting ...")
    console.log(estimation)

    // Create workbook
    const workbook = new ExcelJS.Workbook()
    workbook.creator = 'Nativo'
    workbook.created = new Date()

    // Create sheet
    const sheet = workbook.addWorksheet('Sheet 1', {
        views: [{showGridLines: false,}],
        properties: {defaultRowHeight: 16}
    })

    // Block header
    sheet.getCell('A1').value = estimation.name
    sheet.getCell('A2').value = 'Vytvořeno: ' + new Date(estimation.created).toLocaleString('cs-CZ')
    sheet.getCell('A3').value = 'Aktualizováno: ' + new Date(estimation.updated).toLocaleString('cs-CZ')
    sheet.getCell('A4').value = 'Odhadovaná cena: ' + currencyFormatter.format(estimation.estimated_price)

    // Blocks estimations
    estimation.blocks.forEach((block) => {
        let rowsToWrite = [
            [block.name, block.description, '', currencyFormatter.format(blockPrice(block))],
        ]

        block.rows.forEach((blockRow) => {
            const unit = i18n.t(`estimation.row.metrics.${blockRow.metrics}_interval`,{postProcess: 'interval',count: blockRow.amount})
            let price = 0
            let role = ''
            if (blockRow.metrics === EstimationMetrics.hours) {
                price = blockRow.amount * blockRow.role.rate
                role = blockRow.role.name
            } else {
                price = blockRow.amount * blockRow.price_per_unit
                role = ''
            }
            rowsToWrite.push([
                blockRow.name,
                blockRow.amount + ' ' + unit,
                role,
                currencyFormatter.format(price)
            ])
        })

        const lastRow = sheet.lastRow
        const newRows = sheet.insertRows(lastRow.number + 3, rowsToWrite)

        // Add styling border
        newRows.forEach((row, index, array) => {
            if (index === 0) {
                row.border = {
                    top: {style: 'thin'}
                }
                row.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: {argb: 'dddddd'},
                }
            }
            if (index === array.length - 1) {
                row.border = {
                    bottom: {style: 'thin'}
                }
            }
        })
    })

    // Default fonts
    sheet.eachRow((row, number) => {
        row.font = defaultFont
    })

    // Column specific styles
    sheet.getColumn('A').width = 50
    sheet.getColumn('A').font = {...defaultFont, bold: true}
    sheet.getColumn('B').width = 50
    sheet.getColumn('C').width = 15
    sheet.getColumn('D').width = 15
    sheet.getColumn('D').alignment = {horizontal: "right"}

    // Row specific styles
    sheet.getRow(1).height = 50
    sheet.getRow(1).font = {...defaultFont, bold: true, size: 16}
    sheet.getRow(1).alignment = {vertical: 'middle'}

    // Write to buffer - send to browser
    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
        saveAs(blob, 'CE - ' + estimation.name)
    });
}
