import React from "react";
import clsx from "clsx";
import {env} from "../../../utils/helpers";
import {Check, Close, Help, Warning} from "@material-ui/icons";
import {remove} from '../../../utils/flashes';
import {RootStoreState, useTypedSelector} from "../../store";
import {CSSTransition, TransitionGroup} from 'react-transition-group';

const colors = {
    error: 'bg-red-600',
    info: 'bg-primary',
    success: 'bg-green-500',
    warning: 'bg-yellow-500'
};

const icons = {
    error: <Warning/>,
    info: <Help/>,
    success: <Check/>,
};

export default function FlashMessage() {
    const messages = useTypedSelector((state: RootStoreState) => state.flashes);
    return (
        <TransitionGroup className={"fixed top-0 right-0 m-5 z-50"}>
            {messages?.map((msg) =>
                <CSSTransition key={msg.uid} timeout={env('TRANSITION_DURATION')} classNames="transition">
                    <div className={"min-w-lg"}>
                        <div className={clsx('relative text-white shadow-lg rounded mb-4 p-4', colors[msg.type])}>
                            <div className={'flex items-center'}>
                                {icons[msg.type]}
                                <div className={"ml-2"}>
                                    {msg.message || 'Some error'}
                                </div>
                                <div className={"ml-auto"}>
                                    &nbsp;
                                    <button type="button" onClick={() => remove(msg)} title={"Close"}>
                                        <Close/>
                                    </button>
                                </div>
                            </div>
                            <div
                                className={"absolute bottom-0 left-0 animate-timeout bg-white bg-opacity-50 h-[10px]"}/>
                        </div>
                    </div>
                </CSSTransition>
            )}
        </TransitionGroup>
    );
}
