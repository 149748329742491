import React from "react";
import Paper from "../../common/Paper";
import {blockPrice} from "../../../utils/priceHelpers";
import EstimationViewRow from "./EstimationViewRow";
import {useTranslation} from "react-i18next";

export default function EstimationViewBlock({block}) {
    const {t} = useTranslation();
    return (
        <div className={"break-avoid py-3"}>
            <Paper className={"relative print:border print:border-gray-100"} header={(
                <div className={"flex justify-between items-end"}>
                    <div className={"pr-2"}>
                        <h2 className={"text-xl"}>{block.name}</h2>
                        {block.description && (
                            <p className={"text-gray-500"}>{block.description}</p>
                        )}
                    </div>
                    <span className={"text-xl"}>{t('money', {value: blockPrice(block)})}</span>
                </div>
            )}>
                {block?.rows?.map(row =>
                    <EstimationViewRow key={row.id} row={row}/>
                )}
            </Paper>
        </div>
    );
}
