

class Data implements Record<string, any>{

    public get(key: string | undefined): any {
        if (!key) return {};
        if (this[key] === undefined) {
            this[key] = {};
        }
        return this[key];
    }

}

const data: Data = new Data();

window.addEventListener('beforeunload', () => {
    localStorage.setItem('data', JSON.stringify(data));
});
let dataString = localStorage.getItem('data');
if (dataString) {
    let values = JSON.parse(dataString);
    Object.assign(data, values);
}


export default data;
