import React from "react";

const WavesAnimated = (props) =>
    <svg {...props}
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         preserveAspectRatio="xMidYMid"
         viewBox="0 0 1920 160">
        <g transform="translate(960,65) scale(1,1) translate(-960,-65)">
            <linearGradient id="lg-0.8663515625578389" x1={0} x2={1} y1={0} y2={0}>
                <stop stopColor="#0ea5e9" offset={0}/>
                <stop stopColor="#4f46e5" offset={1}/>
            </linearGradient>
            <path fill="url(#lg-0.8663515625578389)" opacity={0.4}>
                <animate
                    attributeName="d"
                    dur="50s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="0s"
                    values="M0 0L 0 47.74073153461747Q 240 40.380709249346474  480 15.351721899091153T 960 -45.88949923766229T 1440 -97.91829318569464T 1920 -129.42644042138917L 1920 0 Z;M0 0L 0 127.4511916370433Q 240 66.9042585327395  480 37.90222194832625T 960 33.0788609890386T 1440 -72.27484179907105T 1920 -105.56857762055427L 1920 0 Z;M0 0L 0 91.63536888158478Q 240 74.88359758642224  480 43.68291951020736T 960 -11.759779225106556T 1440 -76.28598308349675T 1920 -118.75868350688407L 1920 0 Z;M0 0L 0 47.74073153461747Q 240 40.380709249346474  480 15.351721899091153T 960 -45.88949923766229T 1440 -97.91829318569464T 1920 -129.42644042138917L 1920 0 Z"
                />
            </path>
            <path fill="url(#lg-0.8663515625578389)" opacity={0.4}>
                <animate
                    attributeName="d"
                    dur="50s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-12.5s"
                    values="M0 0L 0 57.27750705157447Q 240 70.71649360197263  480 38.75338033390011T 960 17.063314052564394T 1440 -23.958760917176292T 1920 -110.2973723670201L 1920 0 Z;M0 0L 0 100.79937722158255Q 240 111.74946508554531  480 76.37535130094227T 960 32.809010926444216T 1440 -63.49714722144934T 1920 -133.8744550205143L 1920 0 Z;M0 0L 0 90.65634460371442Q 240 10.733759833425324  480 -5.783054033315793T 960 -35.17486545998372T 1440 -89.49123213620511T 1920 -172.744192616401L 1920 0 Z;M0 0L 0 57.27750705157447Q 240 70.71649360197263  480 38.75338033390011T 960 17.063314052564394T 1440 -23.958760917176292T 1920 -110.2973723670201L 1920 0 Z"
                />
            </path>
            <path fill="url(#lg-0.8663515625578389)" opacity={0.4}>
                <animate
                    attributeName="d"
                    dur="50s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-25s"
                    values="M0 0L 0 69.28022945716289Q 240 81.31907696519501  480 38.420184235956796T 960 -12.43676900029918T 1440 -22.204469107352082T 1920 -95.4632845535357L 1920 0 Z;M0 0L 0 113.0671951993312Q 240 106.54608853990456  480 57.09856872150315T 960 -27.82308584380816T 1440 -72.16445106382952T 1920 -147.48580835093523L 1920 0 Z;M0 0L 0 72.48961465654783Q 240 43.830743954502694  480 6.34987715208468T 960 -39.778215178407294T 1440 -78.13165137471401T 1920 -138.97042599776L 1920 0 Z;M0 0L 0 69.28022945716289Q 240 81.31907696519501  480 38.420184235956796T 960 -12.43676900029918T 1440 -22.204469107352082T 1920 -95.4632845535357L 1920 0 Z"
                />
            </path>
            <path fill="url(#lg-0.8663515625578389)" opacity={0.4}>
                <animate
                    attributeName="d"
                    dur="50s"
                    repeatCount="indefinite"
                    keyTimes="0;0.333;0.667;1"
                    keySplines="0.2 0 0.2 1;0.2 0 0.2 1;0.2 0 0.2 1"
                    begin="-37.5s"
                    values="M0 0L 0 88.26324953053106Q 240 83.56027143685496  480 41.51815753700856T 960 -1.6183068262387792T 1440 -55.593020992211706T 1920 -51.21977665643715L 1920 0 Z;M0 0L 0 122.22060787237231Q 240 85.29688278259584  480 65.36814492711233T 960 -30.917726682868476T 1440 -84.75984128432646T 1920 -148.21668273441958L 1920 0 Z;M0 0L 0 71.54522508378544Q 240 108.86817830675928  480 66.08079657274062T 960 -18.19986404380917T 1440 -106.54988234850724T 1920 -152.1961494552228L 1920 0 Z;M0 0L 0 88.26324953053106Q 240 83.56027143685496  480 41.51815753700856T 960 -1.6183068262387792T 1440 -55.593020992211706T 1920 -51.21977665643715L 1920 0 Z"
                />
            </path>
        </g>
    </svg>;

export default WavesAnimated;
