import React from "react";
import {DefaultNodeProps} from "../index";
import TextPlaceholder from "./TextPlaceholder";
import clsx from "clsx";


export enum LoadingMessages {
    fetching = "Fetching data",
    responseWait = "Waiting for response",
    rendering = "Rendering component"
}

export default function Loading({children, className}: DefaultNodeProps) {
    return (
        <div className={clsx(className, "animate-pulse-fast")}>
            <TextPlaceholder>
                {children?children+"...":null}
            </TextPlaceholder>
        </div>
    );
}
