import React from "react";

export default function CountDown({duration, onComplete = () => undefined}: { duration: number, onComplete: () => void }) {
    const [timer, setTimer] = React.useState(duration);
    const id = React.useRef(null);
    const clear = () => {
        window.clearInterval(id.current)
    }
    React.useEffect(() => {
        id.current = window.setInterval(() => {
            setTimer((time) => time - 1)
        }, 1000)
        return () => clear();
    }, [])

    React.useEffect(() => {
        if (timer <= 0) {
            clear();
            onComplete();
        }
    }, [timer,onComplete])


    return (
        <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 text-center relative my-3 items-center justify-center flex">
            <div className="bg-primary h-2.5 rounded-full absolute transition-all duration-1000 ease-linear left-0" style={{width: `${timer/duration*100}%`}}/>
            <div className='relative z-10 text-xs'>{timer}s</div>
        </div>
    );
}