import {createSlice, original, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit'
import {RoleData} from "../../api/types";
import {apiFetch} from "../../api/common";

export const fetchDefaultRoles = createAsyncThunk('posts/fetchPosts', async () => {
    return await apiFetch<RoleData>(`/roles`);
})

export const defaultRolesSlice = createSlice({
    name: 'defaultRoles',
    initialState: [],
    reducers: {
        add: (state, action: PayloadAction<RoleData>) => {
            return [...original(state), action.payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDefaultRoles.fulfilled as any, (state, action) => {
            return action.payload
        })
    }
})

export const selectDefaultRoles = state => state.roles

export default defaultRolesSlice.reducer;
