import React from "react";
import clsx from "clsx";

export interface PaperProps extends React.HTMLAttributes<any> {
    header?: React.ReactNode,
    subheader?: React.ReactNode,
}

export default function Paper(props: PaperProps) {
    const {children, className, header = null, subheader = null, ...paperProps} = {...props};
    return (
        <div className={clsx("bg-white shadow-md rounded-lg", className)} {...paperProps}>
            {header ? (
                <>
                    <div className={"px-6 py-4"}>
                        {header}
                        {subheader}
                    </div>
                    <hr className={"my-0"}/>
                </>
            ) : null}
            <div className={`px-6 py-4 pb-4`}>
                {children}
            </div>
        </div>
    );
}
