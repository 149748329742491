import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';

export interface IUser {
    id: number,
    name: string,
    email: string
}
export type SecurityUser = {
    id: number | null,
    data: IUser | null,
    isLoggedIn: boolean
}
const storedData = localStorage.getItem("user");

const emptyData: SecurityUser = {
    id: null,
    data: null,
    isLoggedIn: false
};
const initialData: SecurityUser = storedData ? JSON.parse(storedData) : emptyData;

export const userSlice = createSlice({
    name: 'user',
    initialState: initialData,
    reducers: {
        logout: () => {
            localStorage.setItem("user", JSON.stringify(emptyData));
            return emptyData;
        },
        login: (state: Draft<SecurityUser>, action: PayloadAction<IUser>) => {
            state.id = action.payload.id;
            state.isLoggedIn = true;
            state.data = action.payload;
            localStorage.setItem("user", JSON.stringify(state));
            return state;
        },
        update: (state: Draft<SecurityUser>, action: PayloadAction<IUser>) => {
            state.data = action.payload;
            localStorage.setItem("user", JSON.stringify(state));
            return state;
        }
    },
})

export default userSlice.reducer;
