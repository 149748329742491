import React, {useCallback, useEffect, useState} from "react";
import {fetchRoles} from "../../api/roles";
import RoleDetail from "./RoleDetail";
import Button from "../ui/form/Button";
import LoadingSpinner from "../common/loading/LoadingSpinner";
import {Add, Group} from "@material-ui/icons";
import {postChange} from "../../api/estimations";
import DropDown from "../ui/DropDown";
import {useTranslation} from "react-i18next";
import {EstimationData, RoleData} from "../../api/types";

export default function RolesList(props) {
    const {t} = useTranslation();
    const estimation: EstimationData = props.estimation;
    const [roles, setRoles] = useState<RoleData[]>(estimation?.roles || []);
    const [focusRole, setFocusRole] = useState<RoleData>(null);
    const [addRoleLoading, setAddRoleLoading] = useState(false);
    const nameInput = React.createRef<HTMLInputElement>();

    const update = useCallback(async () => {
        setAddRoleLoading(true);
        try {
            const newRoles = await fetchRoles(estimation?.id);
            setRoles(newRoles);
            estimation.roles = newRoles;
        } catch (err) {
            console.error("couldn't fetch", err);
        } finally {
            setAddRoleLoading(false);
        }
    }, [estimation]);

    useEffect(() => {
        update();
    }, [estimation,update]);

    const handleCreateRole = () => {
        setAddRoleLoading(true);
        postChange<RoleData>("role", {estimation_id: estimation.id})
            .then(onInsert)
            .catch(error => console.error(error));
    }
    const onInsert = async (newRole?: RoleData) => {
        const newRoles = [...roles, newRole];
        estimation.roles = newRoles;
        setFocusRole(newRole);
        setRoles(newRoles);
        setAddRoleLoading(false);
        setTimeout(() => {
            setFocusRole(null);
        }, 1000);
    };
    const onChange = async (role?: RoleData, newRole?: RoleData) => {
        roles[roles.indexOf(role)] = newRole;
        const newRoles = [...roles];
        setRoles(newRoles);
        props.onChange(newRoles);
    };

    const onDelete = async (role: RoleData) => {
        await update();
    };

    return (
        <DropDown contentClassName={"w-96"} label={t("Roles")} buttonProps={{bg: 'white', color: 'black', className: 'shadow', icon: Group}}>
            <div className="p-3">
                {roles.map(role =>
                    <RoleDetail focus={focusRole === role} nameInput={nameInput} onDelete={onDelete} onChange={onChange} key={role.id} role={role}/>)
                }
                <div className={"text-center mt-8"}>
                    <Button bg={'primary'} className={"w-full uppercase"} onClick={handleCreateRole}>
                        {addRoleLoading ? <LoadingSpinner className={"inline"}/> : <Add/>}
                        <span className={"ml-2"}>{t('create new role')}</span>
                    </Button>
                </div>
            </div>
        </DropDown>
    );
}
