import React from "react";
import Paper from "../../common/Paper";
import EstimationDetailBody from "../EstimationDetailBody";
import {estimationDetailViews} from "../EstimationDetail";
import EstimationViewBlock from "./EstimationViewBlock";
import Button from "../../ui/form/Button";
import {Print} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import EstimationViewLegend from "./EstimationViewLegend";

export default function EstimationView({estimation}) {
    const {t} = useTranslation();

    const handlePrintClick = () =>
        window.print();

    return (
        <>
            <Helmet>
                <title>{estimation.name || t('unnamed')} ★ Costimation</title>
            </Helmet>
            <div className={"print:hidden mb-4 flex justify-end"}>
                <Button bg={'white'} icon={Print} color={'black'} onClick={handlePrintClick}>
                    {t(`print`)}
                </Button>
            </div>
            <Paper className={"mt-4 mb-3 print:mt-0 relative print:border print:border-gray-100"}
                   header={(<h1 className={"text-2xl"}>{estimation.name}</h1>)}>
                <EstimationDetailBody
                    price={estimation.estimated_price}
                    estimation={estimation}
                    view={estimationDetailViews.public}
                />
            </Paper>

            {estimation.blocks?.map((block: any) =>
                <EstimationViewBlock key={block.id} block={block}/>
            )}
            <EstimationViewLegend estimation={estimation}/>
        </>
    );
}
