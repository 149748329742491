import React from "react";
import clsx from "clsx";
import {DefaultNodeProps} from "../common";
import {Link} from "react-router-dom";
import Title from "../common/Title";
import {useTranslation} from "react-i18next";


export interface HeaderProps extends DefaultNodeProps {
    backlink?: string,
    rightAddon?: any
}

export default function Header({children, rightAddon, className, backlink}: HeaderProps) {
    const { t } = useTranslation();
    return (
        <header className={clsx("mb-2", className)}>
            <div className={"flex justify-between items-end"}>
                <Title className={"text-primary"}>
                    {children}
                </Title>
                {rightAddon||null}
            </div>
            {backlink ? (
                <Link to={backlink}>{t('Back')}</Link>
            ) : null}
        </header>
    );
}
