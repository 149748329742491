import {apiFetch, rejectWithFlashMessage} from "./common";
import {serializeQuery} from "../utils/helpers";
import {estimationDetailView} from "../components/estimation/EstimationDetail";
import {
    BlockData,
    EntityData,
    EntityType,
    Estimation,
    EstimationData,
    LimitedListQuery,
    ListQuery,
    ListResponse,
    MultipleResponse,
    Role,
    RoleData,
    RowData,
    Search,
    SortOrder
} from "./types";
import store from "../components/store";

export const fetchList = async <E extends EntityData>(entity: EntityType, q: ListQuery<E>): Promise<Array<E>> => {
    const query = serializeQuery(q);
    return await apiFetch(`/${entity}s${query ? `?${query}` : ``}`);
};

export const fetchListPaginated = async <E extends EntityData>(entity: EntityType, q: LimitedListQuery<E>, options?: RequestInit): Promise<ListResponse<E>> => {
    const query = serializeQuery(q);
    return await apiFetch(`/${entity}s${query ? `?${query}` : ``}`, options);
}

export const insertEntity = <E extends EntityData>(entity: EntityType, data: E): Promise<E> => {
    delete data.id;
    return postChange<E>(entity, data);
}

export const updateEntity = <E extends EntityData>(entity: EntityType, data: E): Promise<E> => {
    if (!data.id) throw new Error('Missing entity id');
    return postChange<E>(entity, data);
}

export const createRevision = (id: number): Promise<EstimationData> => new Promise(async (resolve, reject) => {
    apiFetch(`/estimations/revision/${id}`)
        .then(resolve)
        .catch(rejectWithFlashMessage(reject));
});

export const fetchEstimations = (order: SortOrder = null, search: Search = null, limit: number, offset: number): Promise<ListResponse<EstimationData>> =>
    new Promise(async (resolve, reject) => {
        const query = serializeQuery({order, search, limit, offset});
        apiFetch<ListResponse<EstimationData>>(`/estimations${query ? `?${query}` : ``}`)
            .then((response) => {
                response.data = response.data.map((e) => new Estimation(e))
                resolve(response);
            })
            .catch(rejectWithFlashMessage(reject));
    });

export const fetchAllEstimations = (order: SortOrder = null, search: Search = null): Promise<EstimationData[]> =>
    new Promise(async (resolve, reject) => {
        const query = serializeQuery({order, search});
        apiFetch(`/estimations${query ? `?${query}` : ``}`)
            .then((data: EstimationData[]) => {
                resolve(data.map((e) => new Estimation(e)))
            })
            .catch(rejectWithFlashMessage(reject));
    });

export const fetchEstimation = (hash: string, view: estimationDetailView): Promise<EstimationData> =>
    new Promise(async (resolve, reject) => {
        apiFetch<EstimationData>(`/estimations/${hash}`)
            .then((e) => resolve(new Estimation(e)))
            .catch(rejectWithFlashMessage(reject));
    });

export const deleteEntity = (entity: EntityType, id: number, cacheErrors: boolean = true) =>
    new Promise(async (resolve, reject) => {
        apiFetch(`/${entity}s/${id}`, {method: "DELETE"})
            .then(resolve)
            .catch(cacheErrors ? rejectWithFlashMessage(reject) : (err) => reject(err));
    });

export const cloneRow = async (row: RowData, others?: Partial<RowData>) => {
    const clone = {...row, ...others};
    delete clone.id;
    delete clone.created;
    delete clone.updated;
    return await insertEntity('row', clone);
};

export const cloneRole = async (row: RoleData, others?: Partial<RoleData>) => {
    const clone = {...row, ...others};
    delete clone.id;
    return await insertEntity('role', clone);
};

export const cloneBlock = async (block: BlockData, others?: Partial<BlockData>,rolesMap?: Record<number,number>) => {
    const clone = {...block, ...others};
    delete clone.id;
    delete clone.rows;
    delete clone.created;
    delete clone.updated;
    const newBlock = await insertEntity('block', clone);
    newBlock.rows = [];
    if (block.rows) {
        for (let row of block.rows) {
            const newRow = await cloneRow(row, {block_id: newBlock.id,role_id: rolesMap?.[row.role_id]||row.role_id});
            newBlock.rows.push(newRow);
        }
    }
    return newBlock;
};

export const cloneEstimation = async (estimation: EstimationData|Partial<EstimationData>) => {
    const clone = {...estimation};
    delete clone.id;
    delete clone.blocks;
    delete clone.created;
    delete clone.updated;
    delete clone.hash;
    delete clone.roles;
    const newEstimation = await insertEntity('estimation', clone);
    newEstimation.blocks = [];
    const rolesMap = {};
    if (estimation.roles) {
        for (let role of estimation.roles) {
            const newRole= await cloneRole(role, {estimation_id: newEstimation.id});
            newEstimation.roles.push(newRole);
            rolesMap[role.id]= newRole.id;
        }
    }

    if (estimation.blocks) {
        for (let block of estimation.blocks) {
            const newRow = await cloneBlock(block, {estimation_id: newEstimation.id},rolesMap);
            newEstimation.blocks.push(newRow);
        }
    }
    return newEstimation;
};

export const reassignRolesInBlockRows = (block, sourceRoles, destinationRoles) => {
    const rows = [];
    //const defaultRole = destinationRoles?.[0] || null;
    block.rows.forEach(row => {
        const _row = {...row};
        const currentRole = sourceRoles.find(role => role.id === _row.role_id)
        const role = destinationRoles.find(role => role.name === currentRole?.name);
        _row.role_id = role?.id /*|| defaultRole.id*/;
        rows.push(_row);
    })
    return {...block, rows: rows}
}

export const addBlockToTemplates = async (block: BlockData, estimationRoles) => {
    return await cloneBlock(
        reassignRolesInBlockRows(block, estimationRoles, store.getState().defaultRoles),
        {estimation_id: null}
    )
};

export const defaultOptions: RequestInit = {
    headers: {'Content-Type': 'application/json'},
    mode: 'cors',
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    cache: 'no-cache',
};

export const insert = async <E extends EntityData>(entity: EntityType, data: EntityData): Promise<E> => {
    if (data.id) throw new Error('Inserted data cannot have ID');
    return await apiFetch(`/${entity}s`, {...defaultOptions, method: 'POST', body: JSON.stringify(data)});
};
export const insertMultiple = async <E extends EntityData>(entity: EntityType, data: EntityData[]): Promise<MultipleResponse<E>> => {
    for (let el of data) if (el) throw new Error('Inserted data cannot have ID');
    return await apiFetch(`/${entity}s`, {...defaultOptions, method: 'POST', body: JSON.stringify(data)});
};
export const update = async <E extends EntityData>(entity: EntityType, data: EntityData): Promise<E> => {
    if (!data.id) throw new Error('Updated data must have ID');
    return await apiFetch(`/${entity}s/${data.id}`, {...defaultOptions, method: 'PUT', body: JSON.stringify(data)});
};
export const updateMultiple = async <E extends EntityData>(entity: EntityType, data: EntityData[]): Promise<MultipleResponse<E>> => {
    for (let el of data) if (!el) throw new Error('Updated data must have ID');
    return await apiFetch(`/${entity}s`, {...defaultOptions, method: 'PUT', body: JSON.stringify(data)});
};
export const postChange = <E extends EntityData>(entity: EntityType, data: Partial<E>): Promise<E> =>
    new Promise(async (resolve, reject) => {
        const path = `/${entity}s${data.id ? '/' + data.id : ''}`;
        apiFetch(path, {
            ...defaultOptions,
            method: data.id ? 'PUT' : 'POST',
            body: JSON.stringify(data)
        })
            .then(resolve)
            .catch(rejectWithFlashMessage(reject));
    });

export type EstimationEntityType = "estimation" | "block" | "row" | "role" | "user";

export type Metrics = "hours" | "pieces";

export type EstimationEntityData = {
    id?: number
    name?: string
    index?: number
}

export type EstimationBlockData = EstimationEntityData & {
    description?: string
    rows?: EstimationRowData[]
    estimation_id?: number
    created: Date
    updated: Date
}

export type EstimationRowData = EstimationEntityData & {
    block_id: number
    role_id?: number
    metrics?: Metrics
    amount?: number
    role?: Role
    price_per_unit?: number
    created: Date
    updated: Date
}
