import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {cloneBlock, deleteEntity, postChange, reassignRolesInBlockRows} from "../../../api/estimations";
import EstimationContext from "./EstimationContext";
import Header from "../../layout/Header";
import Paper from "../../common/Paper";
import {copyInputToClipboard, env, insertAtIndex} from "../../../utils/helpers";
import EstimationEditorBlock from "./EstimationEditorBlock";
import Button, {DragHandle} from "../../ui/form/Button";
import {
    Add,
    DeleteForever, GetApp,
    Link as LinkIcon,
    OpenInNew,
    Share,
    SystemUpdateAlt
} from "@material-ui/icons";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import {estimationPrice} from "../../../utils/priceHelpers";
import Input from "../../ui/form/Input";
import RolesList from '../../roles/RolesList';
import {Confirm} from "../../ui/Confirm";
import Error404 from "../../error/Error404";
import DropDown from "../../ui/DropDown";
import {useTranslation} from "react-i18next";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Modal from "../../ui/Modal";
import BlockTemplates from "./BlockTemplates";
import {EstimationRevisions} from "./EstimationRevisions";
import handleEstimationChange from "./handleEstimationChange";
import EstimationDetailBody from "../EstimationDetailBody";
import {estimationDetailViews} from "../EstimationDetail";
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {BlockData, EstimationData, RoleData} from "../../../api/types";
import clsx from "clsx";
import {DND_TYPE_BLOCK, handleDnD} from "./handleEstimationDnD";
import {flashMessage} from "../../../utils/flashes";
import {Helmet} from 'react-helmet-async';
import store from "../../store";
import {ExportEstimation} from "../../../utils/export"


export default function EstimationEditor({estimation: e}) {
    const {t} = useTranslation();
    const history = useHistory();
    const [estimation, setEstimation] = useState<EstimationData>(e);
    const [blocks, setBlocks] = useState<any>(estimation.blocks);
    const [loading, setLoading] = useState(false);
    const [addBlockLoading, setAddBlockLoading] = useState(false);
    const input = useRef<HTMLInputElement>(null);
    const [price, setPrice] = useState(estimation ? estimationPrice(estimation) : 0);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        addBlockLoading && setAddBlockLoading(false)
        estimation.blocks !== blocks && setBlocks(estimation.blocks);
    }, [estimation, addBlockLoading]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => setEstimation(e), [e])

    if (!e) return <Error404/>;

    const previewLink = `/view${history.location.pathname}`;
    const goToPreview = ()=>{
        window.open(previewLink, '_blank');
    }

    const handleChange = (what: string, value: any) => {
        handleEstimationChange({
            key: 'estimation',
            item: estimation,
            what,
            value,
            setFn: (estimation) => setEstimation(estimation),
            loadingFn: setLoading,
            setBeforePost: true
        });
    };

    const onBlockDelete = (id) => {
        const _estimation = {...estimation};
        _estimation.blocks = _estimation.blocks.filter(block => block.id !== id);
        setEstimation(_estimation);
        setPrice(estimationPrice(_estimation))
    }

    const handleDelete = (estimation: EstimationData) => async () => {
        try {
            await deleteEntity('estimation', estimation.id);
            flashMessage(t('Estimation deleted', {...estimation}), "success");
            history.push('/');
        } catch (e) {
            //flashMessage(e.message, 'error');
        }
    }

    const handleExport = (estimation: EstimationData) => async () => {
        try {
            await ExportEstimation(estimation)
        } catch (e) {
            flashMessage(e.message, 'error');
        }
    }

    const onBlockChange = (block) => {
        const _estimation = {...estimation};
        setBlocks(currBlocks => {
            const foundIndex = currBlocks.findIndex(_block => _block.id === block.id);
            currBlocks[foundIndex] = block;
            return [...currBlocks]
        });
        _estimation.blocks = blocks;
        setPrice(estimationPrice(_estimation));
    }

    const onRolesChange = (roles: RoleData[]) => {
        setEstimation({...estimation, roles});
    }

    const pushBlock = (_new = true, index?: number) => (block: BlockData) => {
        const _estimation = {...estimation};
        const _block = {...block, new: _new, index: index || blocks?.length || 0}
        _estimation.blocks = [...blocks];
        _estimation.blocks = index ? insertAtIndex(_estimation.blocks, index, _block) : [..._estimation.blocks, _block];
        setEstimation(_estimation);
    }

    const handleCreateBlock = () => {
        setAddBlockLoading(true);
        postChange<BlockData>("block", {estimation_id: estimation.id})
            .then(pushBlock(true))
            .catch(error => flashMessage(error, 'error'));
    }

    const copyToClipboard = () => {
        input?.current && copyInputToClipboard(input.current)
        flashMessage('URL copied!', 'info')
    }

    const handleCloneBlock = (block) => {
        const idx = blocks.findIndex(_block => _block === block) + 1;
        cloneBlock({...block, estimation_id: estimation.id}).then(pushBlock(false, idx));
    }

    const handleCloneBlockFromTemplates = (block) => {
        cloneBlock({
            ...reassignRolesInBlockRows(block, store.getState().defaultRoles, estimation.roles),
            estimation_id: estimation.id
        }).then(pushBlock(false));
    }

    const handleDragStart = () => {
        setIsDragging(true);
    }

    const handleDragEnd = (result) => {
        setTimeout(() => setIsDragging(false), env('TRANSITION_DURATION'))
        const _blocks = handleDnD[result.type](blocks, result);
        setBlocks(_blocks);
    }

    return <>
    <EstimationContext.Provider value={estimation}>
            <div className={'md:flex'}>
                <Helmet>
                    <title>{estimation.name || t('unnamed')} ★ Costimation</title>
                    <meta name="description" content={t('Tool for cost estimation')} />
                </Helmet>
                <Header backlink="/">
                    {t('Cost estimation detail')}
                </Header>
                <div className='ml-auto ps-4 flex flex-row flex-wrap items-end gap-2'>
                    <EstimationRevisions estimation={estimation}/>
                    <RolesList estimation={estimation} onChange={onRolesChange}/>
                    <Button bg={'white'} color={'primary'} icon={GetApp}
                            title={t(`Exportovat do CSV`, {estimation})} onClick={handleExport(estimation)}/>
                    <DropDown contentClassName={"w-96"} buttonProps={{
                        bg: 'white',
                        color: 'primary',
                        icon: Share,
                        className: 'shadow',
                        title: t(`Share estimation`, estimation)
                    }}>
                        <div className={'flex p-2 gap-2'}>
                            <input type='url' ref={input} name={'shareLink'}
                                   value={`${window.location.protocol}//${window.location.host}${previewLink}`} className={'w-full'}
                                   readOnly/>
                            <Button bg={'white'} color={'primary'} icon={LinkIcon}
                                    title={t(`Copy to clipboard`, {estimation})} onClick={copyToClipboard}/>
                            <Button bg={'white'} color={'primary'} icon={OpenInNew} onClick={goToPreview}
                                    title={t(`Preview`, {estimation})} />
                        </div>
                    </DropDown>
                    <Confirm relative message={t("Re u sure bro? No way back")} onSuccess={handleDelete(estimation)}>
                        <Button className="shadow" bg={'white'} color={'red'} icon={DeleteForever}
                                title={t(`Delete estimation`, {estimation})}/>
                    </Confirm>
                </div>
            </div>
            <Paper className={"mt-4 mb-3 relative"} header={
                <Input
                    placeholder={t("Estimation name")}
                    className={"w-full flex-grow sm:mr-4 sm:mb-1 input-lg order-2 sm:order-1"}
                    defaultValue={estimation?.name}
                    name={"name"}
                    spellCheck="false"
                    onChange={handleChange}
                />
            }>
                {loading && <LoadingSpinner className={"text-primary absolute -left-6 top-6"}/>}
                <EstimationDetailBody price={price} estimation={estimation} view={estimationDetailViews.editor}/>
            </Paper>

        <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <Droppable droppableId={`${estimation.id}`} type={DND_TYPE_BLOCK}>
                    {({placeholder,droppableProps,innerRef}, {isDraggingOver}) => (
                        <div {...droppableProps} ref={innerRef} className={clsx(isDraggingOver ? 'bg-opacity-5' : 'bg-opacity-0', 'bg-primary transition-colors -m-4 p-4')}>
                            <TransitionGroup>
                                {blocks?.map((block: any, idx) =>
                                    <CSSTransition key={block.id} timeout={env('TRANSITION_DURATION')} classNames="transition">
                                        <Draggable key={block.id} draggableId={`${block.id}`} index={idx}>
                                            {({dragHandleProps,innerRef,draggableProps}, snapshot) => (
                                                <div ref={innerRef} {...draggableProps} className={"relative py-4"}>
                                                    <DragHandle {...dragHandleProps} className={'right-full top-6 translate-x-1/2 z-10'} />
                                                    <EstimationEditorBlock
                                                        block={block}
                                                        isDragging={isDragging}
                                                        onChange={onBlockChange}
                                                        onClone={handleCloneBlock}
                                                        onDelete={onBlockDelete}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    </CSSTransition>
                                )}
                            </TransitionGroup>
                            {placeholder}
                        </div>
                    )}
                </Droppable>
        </DragDropContext>

    </EstimationContext.Provider>
            <div className={"flex text-center my-8 gap-2"}>
                <Button bg={'primary'} color={'white'} icon={addBlockLoading ? LoadingSpinner : Add}
                        className={"w-full uppercase"} onClick={handleCreateBlock}>
                    {t('create new block')}
                </Button>
                <Modal enabled button={t('Templates')} className={'container my-16  p-0'}
                       buttonProps={{bg: "primary", color: "white", icon: SystemUpdateAlt, className: 'w-full uppercase'}}>
                    <BlockTemplates onAdd={handleCloneBlockFromTemplates}/>
                </Modal>
            </div>
    </>;
}
