import {useTranslation} from "react-i18next";
import React from "react";
import {estimationDetailViews} from "./EstimationDetail";
import Link from "../ui/Link";

export default function EstimationDetailBody({price, estimation, view})
{
    const {t} = useTranslation();
    const isInEditor = view === estimationDetailViews.editor;

    return (
        <div className={"md:grid print:grid grid-cols-2"}>
            <div className={"flex justify-start"}>
                <table>
                    <tbody>
                        {isInEditor && (
                            <tr>
                                <td className={"w-[1px] pr-4 font-bold"}>{t('Hash')}:</td>
                                <td className={"text-right"}>{estimation.hash}</td>
                            </tr>
                        )}
                        {isInEditor && estimation.parent_id && (
                            <>
                                <tr>
                                    <td className={"w-[1px] pr-4 font-bold"}>{t('Revision')}:</td>
                                    <td className={"text-right"}>
                                        <Link to={`/${estimation.parent.hash}`}>{estimation.parent.hash}</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"w-[1px] pr-4 font-bold"}>{t('Revision Number')}:</td>
                                    <td className={"text-right"}>{estimation.revision_number}</td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <td className={"w-[1px] pr-4 font-bold"}>{t('Created')}:</td>
                            <td className={"text-right"}>{t('dateTime',{date: new Date(estimation.created)})}</td>
                        </tr>
                        <tr>
                            <td className={"w-[1px] pr-4 font-bold"}>{t('Updated')}:</td>
                            <td className={"text-right"}>{t('dateTime',{date: new Date(estimation.updated)})}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={"flex flex-col items-end justify-end mt-4 md:mt-0"}>
                {t('Estimated cost')}:
                <div className={"text-4xl"}>
                    {t('money',{value: price})}
                </div>
            </div>
        </div>
    );
}
