import {Confirm} from "../../ui/Confirm";
import Button from "../../ui/form/Button";
import React from "react";


export default function ActionElement({action, setLoading, onSuccess, ...other}){
    const {icon, label, onAction, confirm, ...otherProps} = action;
    const Icon = icon || (() => <></>);
    const onClick = async () => {
        try {
            setLoading(true);
            await action.onAction();
            onSuccess();
        }catch (e) {
            console.log(e);
            // flashMessage(t(e.message),'error');//this should be caught earlier
        } finally {
            setLoading(false);
        }
    }

    return confirm ?
        <Confirm  {...other} messageClassName={'text-right'} message={confirm} onSuccess={onClick}>
            <Button {...otherProps}><Icon/>{label}</Button>
        </Confirm>
        : <Button onClick={onClick} {...otherProps} {...other}><Icon/>{label}</Button>
}
