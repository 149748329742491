import React, {useState} from "react";
import Input from "./Input";
import Button from "./Button";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import clsx from "clsx";

const Password = (props) => {
    const {onChange, defaultValue, name, autoComplete, required, placeholder,className, ...anotherProps} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => event.preventDefault();
    return (
        <div className={clsx('relative',className)} {...anotherProps}>
            <Input key="password" name={name}
                   placeholder={placeholder}
                   className={"w-full px-3"}
                   type={showPassword ? 'text' : 'password'}
                   required={required}
                   autoComplete={autoComplete}
                   defaultValue={defaultValue}
                   onChange={onChange}
            />
            <Button
                color={'gray'}
                type={"button"}
                size={false}
                className={"absolute right-[5px] top-1/2 transform -translate-y-1/2"}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                icon={showPassword ? Visibility : VisibilityOff}>
            </Button>
        </div>
    )
};

export default Password;
