import React, {useEffect, useRef, useState} from "react";
import {ExpandMore} from "@material-ui/icons";
import Button, {ButtonProps} from "./form/Button";
import clsx from "clsx";
import {ClickAwayListener} from "@material-ui/core";
import {useWindowSize} from "../../utils/hooks";
import {env} from "../../utils/helpers";
import {CSSTransition} from "react-transition-group";

export interface DropDownProps {
    children: JSX.Element,
    className?: string,
    buttonClassName?: string,
    contentClassName?: string,
    label?: string
    caret?: JSX.Element|false,
    open?: boolean,
    buttonProps?: ButtonProps
}

type DropDownPosition = {
    maxWidth: `calc(${string})` | `${string}vw` | `${string}%`,
    direction: 'left' | 'right'
}

export default function DropDown(props: DropDownProps) {
    const {className, children, label, open, contentClassName, buttonProps} = props;
    const [opened, setOpened] = useState<boolean>(open || false);
    const [width] = useWindowSize();

    const [position, setPosition] = useState<DropDownPosition>({
        maxWidth: '100vw',
        direction: 'left'
    });

    const container = useRef(null);

    const calculatePosition = () => {
        const left = container?.current?.getBoundingClientRect()?.left || 0;
        const right = window.innerWidth - container?.current?.getBoundingClientRect()?.right || 0;
        const max = Math.round((left < right ? left : right) || 0);
        setPosition({
            maxWidth: `calc(100vw - ${max}px - 1rem)`,
            direction: left < right ? 'right' : 'left'
        })
    }

    useEffect(calculatePosition, [container, width]);

    const caret = props.caret !== false
        ?  props.caret
        : <ExpandMore className={`ml-2 transition-all  ${opened ? "rotate-180" : "rotate-0"}`}/>;

    const toggleDropdown = () =>
        setOpened(!opened);

    const handleClickAway = () => {
        setOpened(false)
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={clsx('relative', className)} ref={container}>
                <Button onClick={toggleDropdown} aria-expanded={opened} aria-haspopup="true" {...buttonProps}>
                    {label}
                    {caret}
                </Button>
                <CSSTransition in={opened} unmountOnExit timeout={env('TRANSITION_DURATION')} classNames="scale-fade-in">
                    <div role="menu" aria-orientation="vertical" className={clsx(
                        contentClassName,
                        position.direction === 'right' ? 'origin-top-left left-0' : 'origin-top-right right-0',
                        `z-10 absolute mt-2 rounded-md shadow-lg bg-white`,
                    )} style={{maxWidth: position.maxWidth}}>
                        {children}
                    </div>
                </CSSTransition>
            </div>
        </ClickAwayListener>
    )
}
