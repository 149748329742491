import React, {useEffect, useState} from "react";
import {fetchEstimation} from "../../api/estimations";
import {useHistory, useParams} from "react-router-dom";
import Loading from "../common/loading/Loading";
import EstimationEditor from "./private/EstimationEditor";
import EstimationView from "./public/EstimationView";
import {EstimationData} from "../../api/types";


interface IEstimationDetailProps {
    hash: string
}

type EstimationDetailProps = {
    view: estimationDetailView
}

export type estimationDetailView = 'editor' | 'public';

export enum estimationDetailViews {
    editor = 'editor',
    public = 'public'
}

export default function EstimationDetail({view}: EstimationDetailProps) {
    const history = useHistory();
    const params = useParams<IEstimationDetailProps>();
    const [estimation, setEstimation] = useState<EstimationData>();

    useEffect(() => {
        !params.hash
            ? history.push('404')
            : fetchEstimation(params.hash, view).then(setEstimation);
    }, [history, params.hash, view]);
    if (!estimation) return <Loading/>
    return view === estimationDetailViews.public
        ? <EstimationView estimation={estimation}/>
        : <EstimationEditor estimation={estimation}/>
}
