import React, {Suspense} from 'react';
import {Provider} from 'react-redux'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {Favorite} from "@material-ui/icons";
import Error404 from "./components/error/Error404";
import FlashMessage from "./components/common/flashMessage/FlashMessage";
import Login from "./components/login/Login";
import WavesAnimated from "./components/common/svg/WavesAnimated";
import EstimationList from "./components/estimation/private/EstimationList";
import UserEditor from "./components/user/UserEditor";
import store, {RootStoreState, useTypedSelector} from "./components/store";
import Navigation from "./components/layout/Navigation";
import UserList from "./components/user/UserList";
import EstimationDetail from "./components/estimation/EstimationDetail";
import env from "./utils/env";
import {useTranslation} from "react-i18next";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {fetchDefaultRoles} from "./components/roles/defaultRolesSlice";
import Settings from "./components/settings/Settings";

store.dispatch(fetchDefaultRoles())

function App() {
    const {t} = useTranslation();
    return (
        <HelmetProvider>
            <div className={"flex flex-col min-h-screen"}>
                <Helmet>
                    <title>{t('Tool for cost estimation')} ★ Costimation</title>
                    <meta name="description" content={t('Tool for cost estimation')} />
                </Helmet>
                <WavesAnimated className={"block print:hidden mx-auto absolute"} style={{zIndex: -1}} width={1920} height={160}/>
                {/*<FloatingIconsAnimated className={"block print:hidden mx-auto absolute"} style={{background: "rgba(0, 0, 0, 0)", zIndex: -1}} width={1879} height={2000}/>*/}
                <Navigation/>
                <main>
                    <div className={"container pt-6"}>
                        <FlashMessage/>
                        <Switch>
                            <Route exact path="/404" component={Error404}/>
                            <Route exact path="/login" component={Login}/>
                            <Route exact path="/view/:hash" component={() => <EstimationDetail view={'public'}/>}/>
                            <PrivateRoute exact path="/user/:id?" component={UserEditor}/>
                            <PrivateRoute exact path="/users" component={UserList}/>
                            <PrivateRoute exact path="/settings" component={Settings}/>
                            <PrivateRoute exact path="/" component={EstimationList}/>
                            <PrivateRoute exact path="/:hash" component={() => <EstimationDetail view={'editor'}/>}/>
                            <Route exact component={Error404}/>
                        </Switch>
                    </div>
                </main>
                <footer className={"text-xs text-center text-gray-400 pb-2 pt-6 mt-auto"}>
                    <span className={"hidden print:inline"}>Generated by </span><span
                    className={"capitalize"}>{env.NAME}</span> v{env.VERSION}<br/>
                    Developed with <Favorite className={"text-primary"} fontSize={"small"}/>
                    &nbsp;at&nbsp;<a className="text-gray-400" href={"https://nativo.cz"} target="_blank" rel="noreferrer">Nativo</a>
                </footer>
                {env.DEBUG &&
                <div className={'fixed text-center bottom-0 left-0 m-24'}>
                    <div className={'fire absolute -z-1 bottom-0 translate-y-[50px]'}>
                        {[...Array(50)].map((x, i) => <div className={'particle'} key={i}/>)}
                    </div>
                    DEBUG<br/>
                    MODE ON
                </div>}
            </div>
        </HelmetProvider>
    );
}

const PrivateRoute = ({component: Component, ...rest}) => {
    const user = useTypedSelector((state: RootStoreState) => state.user);
    return (
        <Route {...rest} render={props => (
            user.isLoggedIn ? <Component {...props} /> : <Redirect to="/login" />
        )} />
    );
};

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
    return (
        <Suspense fallback="...is loading">
            <Provider store={store}>
                <Router>
                    <App/>
                </Router>
            </Provider>
        </Suspense>
    );
}
