import React, {forwardRef, MutableRefObject, useEffect, useState} from "react";

export enum fireChangeOnTypes {
    blur = "blur",
    change = "change"
}

export interface InputProps extends Omit<React.TextareaHTMLAttributes<HTMLOrSVGElement>, "onChange"> {
    type?: string
    defaultValue?: string|number,
    onChange?: Function
    name: string
    tagName?: "input"|"textarea",
    placeholder?: string,
    fireChangeOn?: fireChangeOnTypes,
    max?:number,
    min?:number,
    step?:any,
    ref?:any
}

const inputDefaultProps: InputProps = {
    type: "text",
    name: "",
    tagName: "input",
    fireChangeOn: fireChangeOnTypes.change,
    onChange: (what, value) => console.log(what, value)
};

const Input = forwardRef<HTMLInputElement, InputProps>((props: InputProps, ref: MutableRefObject<any>)=>{
    const {onChange, tagName, name, type, placeholder, defaultValue, fireChangeOn, ...inputProps} = {...inputDefaultProps, ...props};
    const normalizedValue = defaultValue === undefined|| defaultValue === null ?'':defaultValue;
    const [value, setValue] = useState(normalizedValue);
    const [hasFocus, setFocus] = useState(false);

    useEffect(()=> {
        !hasFocus && normalizedValue && normalizedValue !== value && setValue(normalizedValue)
    },[normalizedValue, hasFocus, value])

    const handleChange=(e: React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLTextAreaElement>)=> {
        setValue(e.target.value);
        fireChangeOn === fireChangeOnTypes.change && onChange(name, e.target.value);
    }

    const handleBlur =()=> {
        setFocus(false)
        if (value === normalizedValue) return;
        fireChangeOn === fireChangeOnTypes.blur && onChange(name, value);
    };

    const handleFocus = () => {
        setFocus(true)
    }

    const Tag = tagName;
    return (
        <Tag
            {...inputProps}
            ref={ref}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
        />
    );
});

export default Input;
