import {apiFetch, rejectWithFlashMessage} from "./common";
import {logout} from "../components/user";
import {defaultOptions} from "./estimations";

export type SignInParams = {
    email: string,
    password: string
}

const signIn = (data: SignInParams): Promise<object> =>
    new Promise(async (resolve, reject) => {
        apiFetch(`/sign/in`,
            {
                method: 'POST',
                body: JSON.stringify(data),
                ...defaultOptions,
            })
            .then(resolve)
            .catch(reject)
    });

const signOut = () =>
    new Promise(async (resolve, reject) => {
        apiFetch(`/sign/out`)
            .then(logout)
            .catch(rejectWithFlashMessage(reject))
    });

export {signIn, signOut};
