import React, {useContext, useEffect, useRef, useState} from "react";
import EstimationContext from "./EstimationContext";
import {focusOn} from "../../../utils/helpers";
import Input, {fireChangeOnTypes} from "../../ui/form/Input";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import Select from "../../ui/form/Select";
import {deleteEntity} from "../../../api/estimations";
import Button from "../../ui/form/Button";
import {Delete, FileCopy} from "@material-ui/icons";
import {Confirm} from "../../ui/Confirm";
import {useTranslation} from "react-i18next";
import handleEstimationChange from "./handleEstimationChange";
import {rowPrice} from "../../../utils/priceHelpers";


export enum EstimationMetrics {
    hours = "hours",
    pieces = "pieces"
}

const EstimationEditorRow = function (props) {
    const { onChange, onDelete , onClone, ...otherProps} = props;
    const {t} = useTranslation();
    const [row, setRow] = useState(props.row);
    const [loading, setLoading] = useState(false);
    const firstInputRef = useRef<HTMLInputElement>();
    const context = useContext(EstimationContext);

    useEffect(()=> onChange(row), [row, onChange])
    useEffect(()=> focusOn(row.new, firstInputRef), [row.new, firstInputRef])

    const handleDelete = () => {
        setLoading(true);
        deleteEntity('row', row.id).then(() => onDelete(row.id));
    }

    const preSetRow = (_row) => {
        if (row.role_id !== _row.role_id && context.roles) {
            _row.role = context.roles.find(role => role.id === parseInt(_row.role_id))
        }
        setRow(_row)
    }

    const handleChange = (what: string, value: any) => {
        handleEstimationChange({
            key: 'row',
            item: row,
            what,
            value,
            setFn: preSetRow,
            loadingFn: setLoading,
            setBeforePost: true
        })
    }
    const cloneRow = () =>
        onClone(row);

    return (
        <div className={"relative"} {...otherProps}>
            {loading && <LoadingSpinner className={"text-primary absolute -left-12 top-1/2 transform -translate-y-1/2"}/> }
            <div className={"grid grid-cols-12 gap-4 z-30"}>
                {/*env('DEBUG') && <div className={'absolute left-0 translate-x-[-40px] translate-y-2/3 pointer-events-none text-gray-400 text-sm leading-none'}>{row.index}</div>*/}
                <Input ref={firstInputRef} className={"col-span-full md:col-span-5"} placeholder={t("Items name")} defaultValue={row.name} name={"name"} spellCheck="false" onChange={handleChange}/>
                <Input className={"col-span-4 sm:col-span-2 md:col-auto"} defaultValue={row.amount} name={"amount"} min={0} step={1} type={"number"} fireChangeOn={fireChangeOnTypes.change} onChange={handleChange} placeholder={"Amount"} title={"Amount"}/>
                <Select className={"col-span-8 sm:col-span-5 md:col-span-2 cursor-pointer"} name={"metrics"} options={Object.keys(EstimationMetrics).map(k=>({label: t(k),key: k}))} optionKey={'key'} labelKey={'label'} selectedKey={row.metrics} onChange={handleChange}/>
                <div className={"col-span-full sm:col-span-5 md:col-span-2"}>
                    <Metrics row={row} handleChange={handleChange}/>
                </div>
                <div className={"col-span-12 md:col-span-2 h-full flex self-center items-center justify-self-end"}>
                    {t('money',{value: rowPrice(row)})}
                    <div className={'flex gap-1 ml-2'}>
                        {onClone && <Button size={false} className={'p-1'} color={'gray'} bg={'gray'} title={t('Duplicate row',{row})} icon={FileCopy} onClick={cloneRow} />}
                        <Confirm title={t('Delete row',{row})} onSuccess={handleDelete} message={t("Re u sure bro? No way back")}>
                            <Button size={false} className={'p-1'} color={'gray'} bg={'gray'} icon={Delete}/>
                        </Confirm>
                    </div>
                </div>
            </div>
        </div>

    );
}

const Metrics = ({row,handleChange})=>{
    const context = useContext(EstimationContext);
    const {t} = useTranslation();
    if( row.metrics === EstimationMetrics.hours){
        if(context){
            return  <Select className={"w-full cursor-pointer invalid:text-gray-500"} required name={"role_id"} prompt={t('Select role')}
                    options={context.roles} optionKey={"id"} selectedKey={row.role_id} labelKey={"name"} onChange={handleChange}/>
        }else if(row.role){
            return <Input readOnly className={"w-full"} defaultValue={row.role.name} name={"role"}/>
        }else {
            return <></>
        }
    }else{
        return <Input className={"w-full cursor-pointer"} defaultValue={row.price_per_unit} name={"price_per_unit"} placeholder={t("Price/unit")} type={"number"} fireChangeOn={fireChangeOnTypes.change} onChange={handleChange}/>
    }
}

export default EstimationEditorRow;
