import React, {useEffect, useState} from "react";
import {fetchList} from "src/api/estimations";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import EstimationEditorBlock from "./EstimationEditorBlock";
import {env} from "src/utils/helpers";
import Overlay from "../../ui/Overlay";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import {BlockData} from "../../../api/types";
import {handleDnD} from "./handleEstimationDnD";
import {DragDropContext} from "react-beautiful-dnd";

interface BlockTemplatesParams {
    onAdd: (block: BlockData) => void
}

export default function BlockTemplates({onAdd}: BlockTemplatesParams) {
    const [templates, setTemplates] = useState<BlockData[]>(null);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const loadData = async () => {
        const response = await fetchList<BlockData>("block", {
            where: {estimation_id: null},
            order: {created: 'DESC'},
            detailed: true
        });
        setTemplates(response);
    }

    useEffect(() => {
        loadData();
    }, []);

    const onBlockDelete = (id) => {
        setTemplates(templates.filter(block => block.id !== id));
    }
    const handleDragStart = () => {
        setIsDragging(true);
    }

    const handleDragEnd = (result) => {
        setTimeout(() => setIsDragging(false), env('TRANSITION_DURATION'))
        const _blocks = handleDnD[result.type](templates, result);
        setTemplates(_blocks);
    }
    return (
        <>
            <Overlay visible={!templates} className='flex items-start justify-center py-24 z-10'>
                <LoadingSpinner className='text-primary' size='5rem'/>
            </Overlay>
            <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
                <TransitionGroup>
                    {templates?.map((block) =>
                        <CSSTransition key={block.id} timeout={env('TRANSITION_DURATION')} classNames="transition">
                            <EstimationEditorBlock
                                className={'my-8'}
                                block={block}
                                onDelete={onBlockDelete}
                                onChange={() => undefined}
                                onSelect={onAdd}
                                onTemplateAdded={loadData}
                                isDragging={isDragging}
                            />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </DragDropContext>
        </>);
}
