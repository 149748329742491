import {createSlice, original, PayloadAction} from '@reduxjs/toolkit'

export type FlashType = 'error' | 'info' | 'success' | 'warning';
export const flashTypes : Record<FlashType, FlashType>={
    error:  'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
}
export interface IFlashMessage {
    uid: number,
    message: string,
    type: FlashType
}

export const flashesSlice = createSlice({
    name: 'flashMessages',
    initialState: [],
    reducers: {
        add: (state, action: PayloadAction<IFlashMessage>) => {
            //if (original(state).findIndex(msg => msg.message === action.payload.message) !== -1) return;
            return [...original(state), action.payload];
        },
        remove: (state, action: PayloadAction<IFlashMessage>) => {
            return [...original(state).filter(msg => msg !== action.payload)];
        }
    },
})

export default flashesSlice.reducer;