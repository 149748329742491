
export type EntityType = "estimation" | "block" | "row" | "role" | "user";
export type EstimationsColumn = "name" | "estimated_price" | "updated" | "created";
export type SortDirection = "ASC" | "DESC";
export type SortOrder = {
    [K in EstimationsColumn]?: SortDirection
};
export type Search = {
    [K in EstimationsColumn]?: string
};

export type ListResponse<T extends EntityData> = {
    data: Array<T>
    count: number
    limit: number
    offset: number
}

export type Metrics = "hours" | "pieces";

export type EntityData = {
    id?: number
    name?: string
}

export type BlockData = EntityData & {
    description?: string
    rows?: RowData[]
    estimation_id?: number
    created: Date
    updated: Date
}

export type RowData = EntityData & {
    block_id: number
    role_id?: number
    index?: number
    metrics?: Metrics
    amount?: number
    role?: Role
    price_per_unit?: number
    created: Date
    updated: Date
}

export type EstimationData = EntityData & {
    defaultRoles?: false
    created: Date
    updated: Date
    estimated_price: number
    sum_hours: number
    roles?: any[]
    blocks?: BlockData[]
    parent_id: number | null
    parent?: EstimationData | null
    hash: string
    revision: string | null
    revision_count?: number
    revision_number?: number
}
export type RoleData = EntityData & {
    estimation_id?: number
    id?: number
    name?: string
    rate?: number
    order?: number
}

export type ListQuery<E> = {
    order?: SortOrder
    search?: Search
    where?: Partial<E>
    detailed?: boolean,
    limit?: number,
    offset?: number
}
export type LimitedListQuery<E> = ListQuery<E> & {
    limit?: number
    offset?: number
}

export class Entity {
    id: number;

    constructor(data: any) {
        Object.assign(this, data);
    }
}

export class Estimation extends Entity implements EstimationData {
    id: number;
    name: string;
    created: Date;
    estimated_price: number;
    sum_hours: number;
    hash: string;
    parent_id: number | null;
    revision: string | null;
    updated: Date;

    constructor(data?: EstimationData) {
        super(data);
        Object.assign(this, data);
        this.updated = new Date(data.updated);
        this.created = new Date(data.created);
    }
}

export class Block extends Entity implements BlockData {
    name: string;
    updated: Date;
    created: Date;
    description: string;
    estimation_id: number;
    rows: RowData[];

    constructor(data?: BlockData) {
        super(data);
        Object.assign(this, data);
        this.updated = new Date(data.updated);
        this.created = new Date(data.created);
    }
}

export class Row extends Entity implements RowData {
    amount: number;
    block_id: number;
    created: Date;
    id: number;
    metrics: Metrics;
    name: string;
    price_per_unit: number;
    role: Role;
    role_id: number;
    updated: Date;

    constructor(data?: RowData) {
        super(data);
        Object.assign(this, data);
        this.updated = new Date(data.updated);
        this.created = new Date(data.created);
    }
}

export class Role extends Entity implements RoleData {
    estimation_id?: number
    name?: string
    rate?: number
    order?: number

}

export type MultipleResponse<E> = {
    data?: E[]
    errors?: Record<number, string[]>,
    message?: string
}
