import React, {ReactElement, useState} from "react";
import clsx from "clsx";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import {UnfoldMore} from "@material-ui/icons";
import {tryCall} from "../../../utils/helpers";

export type ThemeColor = "primary" | "secondary" | "gray" | "black" | "white" | "red";
export type ButtonSize = 'big' | 'small';
export type ButtonElement = HTMLButtonElement | HTMLAnchorElement ;

export interface ButtonProps extends React.ButtonHTMLAttributes<ButtonElement>   {
    Tag?: 'a' | 'button',
    icon?: (any) => JSX.Element,
    bg?: ThemeColor,
    size?: ButtonSize | false,
    color?: ThemeColor,
    iconSize?: 'inherit' | 'default' | 'small' | 'large',
    outline?: boolean,
    rounded?: boolean,
    disabled?: boolean,
    target?: string,
    href?: string
}

const buttonProps: ButtonProps = {
    Tag: "button",
    bg: null,
    color: "white",
    size: 'small',
    outline: true,
    disabled: false,
    rounded: true,
    icon: () => <></>,
};

const colors: Record<ThemeColor, string> = {
    primary: "text-primary hover:text-primary-dark",
    secondary: "text-secondary hover:text-secondary-dark",
    gray: "text-gray-400 hover:text-gray-500",
    red: "text-red-500 hover:text-red-600",
    black: "text-black hover:text-gray-900",
    white: "text-white hover:text-gray-100",
};
const backgrounds: Record<ThemeColor, string> = {
    primary: "bg-primary bg-gradient-to-r hover:bg-primary-dark",
    secondary: "bg-secondary hover:bg-secondary-dark",
    gray: "bg-gray-100 hover:bg-gray-200",
    red: "bg-red-500 hover:bg-red-700",
    black: "bg-black hover:bg-gray-900",
    white: "bg-white hover:bg-gray-100",
};

const focus = 'focus:outline-black focus-visible:outline-black';
/*
const outlines: Record<ThemeColor, string> = {
    primary: focus,
    secondary: focus,
    gray: focus,
    red: focus,
    black: focus,
    white: focus,
};*/

const sizes: Record<ButtonSize, string> = {
    small: 'p-2',
    big: 'p-2 px-4',
}

export default function Button(props: ButtonProps) {
    const {Tag, className, bg, color, rounded, outline, disabled, children, size, icon, iconSize, ...otherProps}
        = {...buttonProps, ...props};
    const Icon = icon;
    return (
        <Tag
            className={clsx(
                `flex items-center justify-center leading-none transition-colors        `,
                disabled && 'cursor-not-allowed',
                color && colors[color],
                bg && backgrounds[bg],
                rounded && 'rounded',
                size && sizes[size],
                outline && focus,
                className)} {...otherProps}>
            <Icon fontSize={iconSize} className={clsx('pointer-events-none', children && 'mr-1')}/>
            {children}
        </Tag>
    );
}

export const ButtonWithLoading = ({children, onClick,icon, ...others}): ReactElement => {
    let [loading, setLoading] = useState<boolean>(false);
    const handleClick = async () => await tryCall(onClick, setLoading);
    return (
        <Button type={"button"} bg={'white'} color={'black'} icon={loading ? LoadingSpinner : icon}
                className="shadow" onClick={handleClick} {...others}>
            {children}
        </Button>);
}

export const DragHandle = ({className,...others})=>
    <button {...others} className={clsx("absolute cursor-drag shadow rounded  bg-white hover:bg-primary text-gray-300 transition-colors",className)}>
        <UnfoldMore fontSize={'small'} />
    </button>;
