import {useTranslation} from "react-i18next";
import debounce from "lodash.debounce";
import {assignOrDelete, isEmpty} from "../../../utils/helpers";
import clsx from "clsx";
import Button from "../../ui/form/Button";
import {ArrowDownward, ArrowUpward, Search, Sort, Sync} from "@material-ui/icons";
import React from "react";
import Input, {fireChangeOnTypes} from "../../ui/form/Input";
const sortBehaviour = {
    'undefined': 'ASC',
    'ASC': 'DESC',
    'DESC': undefined
};

const icons = {
    'ASC': <ArrowUpward className={'inline'}/>,
    'DESC': <ArrowDownward className={'inline'}/>,
    'undefined': <Sort className={'inline'}/>,
};
const orderInfo = (order, column) => {
    if (order[column]) {
        const keys = Object.keys(order);
        if (keys.length > 1) {
            return `(${keys.indexOf(column) + 1})`;
        }
    }
};


export default function GridHeader<Entity>({columns, multiSort, search, actions, order, setSearch, setOrder}) {
    const {t} = useTranslation();
    let _search = search;
    const debouncedSearch = debounce(() => setSearch(_search), 500);
    const searchBy = (prop: keyof Entity, text: string) => {
        _search = assignOrDelete(_search, prop, text);
        debouncedSearch();
    };
    const resetSearch = () => setSearch({});
    const sortBy = (prop: keyof Entity) => () => {
        const direction = sortBehaviour[String(order[prop])];
        if (multiSort) {
            setOrder(assignOrDelete(order, prop, direction));
        } else {
            setOrder({[prop]: direction});
        }
    };
    return (
        <thead>
        <tr>
            {columns.map((column,i) => (
                <TableTh key={`${column.key||i}`} className={clsx("text-left", column.className)}>
                    <div className={'flex justify-between '}>
                        {t(column.label)}
                        {column.key &&  <SortButton column={column.key} order={order} sortBy={sortBy}/>}
                    </div>
                </TableTh>
            ))}
            {actions &&
            <TableTh className={"text-right align-top overflow-hidden sticky right-0 bg-white bg-clip-border"}>
                {t('Action')}
            </TableTh>
            }
        </tr>
        <tr>
            {columns.map((column,i) => (
                <TableTh key={`${column.key||i}`} className={clsx("text-left", column.className)}>
                    {column.key && <SearchField name={column.key} defaultValue={search[column.key]}
                                                onChange={searchBy}/>}
                </TableTh>
            ))}
            {actions &&
            <TableTh className={"text-right align-top overflow-hidden"}>
                <div className={'flex justify-end gap-2'}>
                    {isEmpty(search) ||
                    <Button type={'reset'} color={'red'} icon={Sync} onClick={resetSearch}
                            title={t('Reset Search')}/>
                    }
                </div>
            </TableTh>
            }
        </tr>
        </thead>
    );
}

const SearchField = (props) =>
    <div className="relative">
        <Input {...props} fireChangeOn={fireChangeOnTypes.change}
               className="block text-gray-400 w-full hover:text-black transition-colors focus:outline-none"/>
        <Search className="top-0 text-gray-200 mt-2 absolute pointer-events-none right-0"/>
    </div>;

const SortButton = ({column, order, sortBy}) =>
    <Button size={false} color={'gray'} type="button" onClick={sortBy(column)} className={'p-0'}>
        {orderInfo(order, column)}{icons[`${order[column]}`]}
    </Button>;
const TableTh = ({children, className, ...otherProps}: React.HTMLAttributes<any>) =>
    <th {...otherProps} className={clsx("p-2", className)}>{children}</th>;