import React from "react";
import {DefaultNodeProps} from "./index";
import clsx from "clsx";

export default function Title({children, className}: DefaultNodeProps) {
    return (
        <h1 className={clsx("text-2xl",className)}>
            {children}
        </h1>
    );
}