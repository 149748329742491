import {BlockData, EstimationData, RowData} from "../api/types";

export const estimationPrice = (estimation: EstimationData) => {
    return estimation.blocks?.reduce((a: number, b: any) => a + blockPrice(b), 0) || 0;
}

export const blockPrice = (block: BlockData) => {
    return block.rows?.reduce((a: number, b: any) => a + rowPrice(b), 0) || 0;
}

export const rowPrice = (row: RowData) => {
    return row.metrics === "hours" ? row.amount * (row.role?.rate || 0) : row.amount * row.price_per_unit;
}
