export default function Pagination(params) {
    const {limit, count, offset, onChange,className} = params;
    const max = Math.ceil(count/limit);
    const steps = [];
    for (let i = 0; i < max; i++) {
        steps.push([i+1, i * limit]);
    }
    return <>
        {steps.length > 1 && <div className={'flex flex-row my-1 justify-center'+(className||'')}>
            {steps.map(([site, newOffset]) =>
                <button key={site} className={`p-2 focus:outline-black focus-visible:outline-black ${offset === newOffset ? 'text-gray-400 disabled' : 'text-primary cursor-pointer'}`}
                      onClick={() => onChange(newOffset, site)}>{site}</button>)
            }
        </div>}
    </>
}