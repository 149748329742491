import React, {ReactElement} from "react";
import {useHistory} from "react-router-dom";
import {cloneEstimation, deleteEntity, fetchEstimation, fetchList} from "src/api/estimations";
import {Add, Delete, FileCopy} from "@material-ui/icons";
import {ButtonWithLoading} from "../../ui/form/Button";
import {useTranslation} from "react-i18next";
import {ActionFactory, Column, Grid} from "src/components/common/grid/Grid";
import {EstimationData, RoleData} from "../../../api/types";
import {flashMessage} from "../../../utils/flashes";

export default function EstimationList() {
    const {t} = useTranslation();
    const history = useHistory();


    const columns: Column<EstimationData>[] = [
        //{key: null, label: '#', value: (e, i, data) => data.offset + i + 1, className: 'text-gray-400 text-xs'},
        {key: 'id', label: '#', className: 'text-gray-400 text-xs'},
        {key: 'name', label: 'Name', className: 'max-w-[300px] overflow-ellipsis overflow-hidden'},
        {key: 'revision', label: 'Revision', className: 'max-w-[300px] overflow-ellipsis overflow-hidden',value:(e)=>e.parent_id?`#${e.parent_id} (${e.revision_number})`:''},
        {key: 'estimated_price', label: 'Estimated price', value: (e) => t('money', {value: e.estimated_price})},
        {
            key: 'updated',
            label: 'Updated',
            value: e => t('dateTime', {date: new Date(e.updated)}),
            cellProps: (e) => ({title: t("dateAgo", {date: new Date(e.updated)})})
        },
        {
            key: 'created',
            label: 'Created',
            value: e => t('dateTime', {date: new Date(e.created)}),
            cellProps: (e) => ({title: t("dateAgo", {date: new Date(e.created)})})
        },
    ];

    const handleCreateRow = async (redraw: () => void, setLoading) => {
        try {
            setLoading(true);
            const e = await cloneEstimation({
                roles: await fetchList<RoleData>('role', {where: {estimation_id: null}})
            });
            history.push(`/${e.hash}`)
        } catch (e) {
            flashMessage(t(e.message), 'error')
        } finally {
            setLoading(false);
        }
    }

    const handleDelete = async (estimation: EstimationData) => {
        await deleteEntity('estimation', estimation.id);
        return true;
    }
    const handleClone = async (estimation: EstimationData) => {
        const detail = await fetchEstimation(estimation.hash, 'editor');
        await cloneEstimation(detail);
        return true;
    }

    const actions: ActionFactory<EstimationData>[] = [
        (e) => ({
            onAction: () => handleClone(e),
            confirm: t("Re u sure bro?"),
            title: t("Clone estimation", {estimation: e}),
            icon: FileCopy,
            color: 'secondary',
            size: false
        }),
        (e) => ({
            onAction: () => handleDelete(e),
            confirm: t("Re u sure bro? No way back"),
            title: t("Delete estimation", {estimation: e}),
            icon: Delete,
            color: 'red',
            size: false
        }),
    ];

    const globalActions = (redraw: () => void, setLoading: (bool) => void): ReactElement =>
        <ButtonWithLoading icon={Add}
                           title={t('Create new estimation')}
                           onClick={() => handleCreateRow(redraw, setLoading)}>
            <span className="hidden sm:inline">{t('Create new estimation')}</span>
        </ButtonWithLoading>

    return (
        <Grid<EstimationData>
            title={t('Cost estimation list')}
            keyProp='hash'
            //where={{parent_id: null}}
            link={(e) => `/${e.hash}`}
            entity={'estimation'}
            defaultOrder={{created: "DESC"}}
            columns={columns}
            actions={actions}
            globalActions={globalActions}
            storageKey={'estimationsList'}
        />
    );
}


