import {configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import flashes from "../utils/flashes/flashesSlice";
import user from "./user/userSlice";
import defaultRoles from "./roles/defaultRolesSlice";

/*const asyncFunctionMiddleware = storeAPI => next => action => {
    console.log("111111")
    // If the "action" is actually a function instead...
    if (typeof action === 'function') {
        // then call the function and pass `dispatch` and `getState` as arguments
        return action(storeAPI.dispatch, storeAPI.getState)
    }

    // Otherwise, it's a normal action - send it onwards
    return next(action)
}
const middlewareEnhancer: Middleware = applyMiddleware(asyncFunctionMiddleware)*/
const store = configureStore({
    reducer: {flashes, user, defaultRoles}
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootStoreState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type TypedDispatch = typeof store.dispatch

export const useTypedDispatch = () => useDispatch<TypedDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootStoreState> = useSelector

export default store;
