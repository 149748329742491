import {env} from "../utils/helpers";
import {logout} from "../components/user";
import {flashMessage} from "../utils/flashes";

export type Abortable = (ac: AbortController) => void;

const endpoint = env('API_POINT_URL');
const defaultFetchOptions: RequestInit = {
    credentials: 'include',
    headers: {
        accept: 'application/json'
    }
};

export type ResponseBody<T extends any> = T | ErrorBody | string | null;

export class ApiError<T> extends Error {
    status: number;
    response: Response;
    responseBody: ResponseBody<T>;
    previous?: Error;
    flashDisplayed: boolean;

    constructor(message: string, status: number, response: Response, responseBody?: ResponseBody<T>, previous?: Error, flashDisplayed = false) {
        super(message);
        this.status = status;
        this.responseBody = responseBody;
        this.response = response;
        this.previous = previous;
        this.flashDisplayed = flashDisplayed;
    }
}

export class UnauthorizedError extends Error {

}

export type ErrorBody = {
    message: string,
    code: number,
}

const parseBody = async <T extends any>(response: Response): Promise<T> => {
    const contentType = response.headers.get('content-type');
    try {
        if (contentType.includes('application/json')) {
            return await response.json();
        } else {
            return {
                message: await response.text()
            } as T;
        }
    } catch (e) {
        if (handleError(e)) {
            throw new ApiError(
                `Unable to parse response '${e.message}'`,
                response.status, response, null, e, true);
        }
    }
}

export const handleResponse = async <T extends any = unknown>(response: Response): Promise<T> => {

    if (response.status === 403) {
        logout();
        throw new UnauthorizedError();
    } else if (response.ok) {
        return await parseBody<T>(response);
    } else {
        const body: ErrorBody = await parseBody(response);
        throw new ApiError(
            body.message || `Error ${response.status} ${response.statusText}`,
            response.status, response, body
            //await response.json()
        );
    }
}

export const apiFetch = async <T>(path, options?: RequestInit): Promise<T> => {
    const response = await fetch(`${endpoint}${path}`, {...defaultFetchOptions, ...options});
    return await handleResponse<T>(response);
}

/*export const apiFetch = async <T>(path, options?: RequestInit): Promise<T> =>
    new Promise(async (resolve, reject) => {
        const response = await fetch(`${endpoint}${path}`, {...defaultFetchOptions, ...options});
        handleResponse<T>(response)
            .then(resolve)
            .catch(rejectWithFlashMessage(reject))
    })*/


export const handleError = (error: Error): boolean => {
    if (error.name !== 'AbortError') {
        console.error(error);
        flashMessage(error.message, 'error')
        return true;
    }
    return false;
}
export const rejectWithFlashMessage = (reject) => (error) => {
    handleError(error);
    reject(error);
}
