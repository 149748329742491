import {apiFetch, rejectWithFlashMessage} from "./common";
import {serializeQuery} from "../utils/helpers";
import {RoleData} from "./types";

export const fetchRoles = (estimationId?: number): Promise<RoleData[]> =>
    new Promise(async (resolve, reject) => {
        const where = estimationId ? {estimation_id: estimationId} : {};
        const query = serializeQuery(where);
        apiFetch(`/roles${query ? `?${query}` : ``}`)
            .then(resolve)
            .catch(rejectWithFlashMessage(reject));
    });

