import React, {useEffect, useState} from "react";
import {InputProps} from "./Input";
import clsx from "clsx";
import env from "../../../utils/env";

export interface SelectProps extends InputProps {
    type?: string
    defaultValue?: string | number,
    onChange?: Function
    name: string,
    options: any[],
    optionKey?: string,
    selectedKey: string,
    labelKey?: string,
    prompt?: string
}

export default function Select(props: SelectProps) {
    const {onChange, name, options, optionKey, selectedKey, labelKey, prompt, ...inputProps} = {...props};
    const [value, setValue] = useState(selectedKey);

    useEffect(() => setValue(selectedKey), [selectedKey])

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(e.target.value);
        onChange(name, e.target.value);
    }
    return (
        <select  {...inputProps} className={clsx(inputProps.className, value ? '' : 'text-gray-400')}
                 onChange={handleChange} value={value}>
            {prompt && <option disabled={props.required}>{prompt}</option>}
            {options && options.map(option =>
                optionKey
                    ? <option key={option[optionKey]} value={option[optionKey]}>{env.DEBUG && `#${option[optionKey]} `}{option[labelKey]}</option>
                    : <option key={option} value={option}>{option}</option>
            )}
        </select>
    );
}
