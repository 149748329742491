import format from "date-fns/format";
import {MutableRefObject} from "react";

export const insertBeforeLastOccurrence = (haystack: string, needle: string, insert: string) => {
    const idx = haystack.lastIndexOf(needle);
    return idx > -1
        ? haystack.substring(0, idx) + insert + haystack.substring(idx)
        : haystack;
}

export const insertAtIndex = (arr, index, item) => [
    ...arr.slice(0, index),
    item,
    ...arr.slice(index)
]

export const copyInputToClipboard = (input: HTMLInputElement) => {
    input.select();
    input.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");
}

export const env = (key: string): any => {
    const value: any = process?.env?.[`REACT_APP_${key}`];
    return isNaN(value) ? value : parseInt(value);
}

export const focusOn = (shouldFocus: boolean, ref: MutableRefObject<HTMLElement>, transitioned = true) => {
    //!shouldFocus && ref?.current?.blur();
    shouldFocus && ref?.current && setTimeout(() => ref?.current?.focus(), transitioned ? env('TRANSITION_DURATION') : 0);
}


export const getUserData = () => {
    const json = localStorage.getItem("user");
    return json ? JSON.parse(json) : null;
}

export const objectAssignExisting = (original: object, modifier: object) => {
    Object.keys(modifier).forEach(key => {
        if (key in original)
            original[key] = modifier[key];
    });
    return original;
}

export const confirm = (msg) => window.confirm(msg);

export const formatDateTime = (datetime: string | Date) => {
    try{
        return format(new Date(datetime), 'd. M. Y kk:mm:ss') ;
    }catch (e) {
        return 'invalid datetime';
    }
}


export const objectsAreEqual = (a: object, b: object) =>
    JSON.stringify(a) === JSON.stringify(b);

export const objectToFormData = (someObject) => {
    const formData = new FormData();
    for (let [key, value] of Object.entries(someObject)) {
        formData.append(key, String(value));
    }
    return formData;
}

export const serializeQuery = (params: Array<string> | Object | undefined, prefix: string = ''): string => {
    if (params === undefined) return undefined;
    if (params === null) return `${prefix}=${encodeURIComponent(null)}`;
    const query = Object.keys(params).map((key) => {
        const value = params[key];
        if (params.constructor === Array) {
            key = `${prefix}[]`;
        } else if (params.constructor === Object) {
            key = (prefix ? `${prefix}[${key}]` : key);
        }
        if (typeof value === 'object') {
            return serializeQuery(value, key);
        } else if (value !== undefined) {
            return `${key}=${encodeURIComponent(value)}`;
        }
        return undefined;
    });
    return [].concat.apply([], query).filter(val => val !== undefined && val !== '').join('&');
}
export const assignOrDelete = (data, key, value) => {
    data = {...data, [key]: value};
    if (!value) delete data[key];
    return data;
}
export const isEmpty = (obj: object): boolean => {
    for (let key in obj) return false;
    return true;
}
export const tryCall = async (callback, progress: (boolean) => any) => {
    try {
        progress(true);
        return await callback();
    } finally {
        progress(false);
    }
}
