import React from "react";
import {DefaultNodeProps} from "../index";

export default function TextPlaceholder({children}: DefaultNodeProps) {
    return (
            <div className={"h-full p-4 bg-gray-100 text-gray-400 rounded"}>
                {children}
            </div>
    );
}
