import React from "react";
import {Add, Delete} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import Button from "../ui/form/Button";
import {useTranslation} from "react-i18next";
import {ActionFactory, Column, Grid} from "../common/grid/Grid";
import {deleteEntity} from "../../api/estimations";
import {IUser} from "./userSlice";

const columns: Column<IUser>[]=[
    {key: 'id',label: 'ID',className: 'w-[5rem]'},
    {key: 'name',label: 'name'},
    {key: 'email',label: 'E-mail'}
];

export default function UserList()
{
    const {t} = useTranslation();
    const history = useHistory();

    const handleCreateUser = () => history.push('/user')

    const globalActions = ()=>
        <Button bg={'white'} color={"black"} title={t('Create new user')} icon={Add} type={"button"} onClick={handleCreateUser}>
            <span className={"hidden sm:inline"}>{t('Create new user')}</span>
        </Button>;

    const actions :ActionFactory<IUser>[] = [
        (user)=>({
            onAction: async ()=> {
                await deleteEntity('user',user.id);
                return true;
            },
            icon: Delete,
            color: 'red',
            confirm: t("Re u sure bro? No way back"),
            title: t('Delete user',{...user}),
            size: false
        })
    ];

    return (
        <Grid<IUser> multiSort={false} entity={'user'} keyProp={'id'} columns={columns} link={(user: IUser)=>`/user/${user.id}`} title={t('Users')} actions={actions} globalActions={globalActions}/>
    );
};
