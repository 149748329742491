import React, {useContext, useEffect, useRef, useState} from "react";
import Paper from "../../common/Paper";
import EstimationEditorRow from "./EstimationEditorRow";
import {Add, Check, Delete, FileCopy, Save} from "@material-ui/icons";
import {env, focusOn, insertAtIndex} from "../../../utils/helpers";
import EstimationContext from "./EstimationContext";
import {Confirm} from "../../ui/Confirm";
import {useTranslation} from "react-i18next";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {blockPrice} from "../../../utils/priceHelpers";
import handleEstimationChange from "./handleEstimationChange";
import {addBlockToTemplates, cloneRow, deleteEntity} from "../../../api/estimations";
import Input from "../../ui/form/Input";
import Button, {DragHandle} from "../../ui/form/Button";
import LoadingSpinner from "../../common/loading/LoadingSpinner";
import {Draggable, Droppable} from 'react-beautiful-dnd';
import clsx from "clsx";
import {RowData} from "../../../api/types";
import {DND_TYPE_ROW} from "./handleEstimationDnD";
import {flashMessage} from "../../../utils/flashes";

export default function EstimationEditorBlock(props) {

    const {block} = props;
    const {t} = useTranslation();
    const {onChange, onClone, onSelect, onDelete,onTemplateAdded, isDragging, className, ...otherProps} = props;
    const context = useContext(EstimationContext);
    const [price, setPrice] = useState(blockPrice(block));
    const [loading, setLoading] = useState(false);
    const [addRowLoading, setAddRowLoading] = useState(false);
    const firstInputRef = useRef<HTMLInputElement>();

    useEffect(() => addRowLoading && setAddRowLoading(false), [block, addRowLoading]);
    useEffect(() => block.rows?.length && onChange(block), [price]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => focusOn(block.new, firstInputRef), [block.new, firstInputRef])

    const resolveChange = (block) => {

        onChange(block);
    }

    const handleChange = (what: string, value: any) => {
        handleEstimationChange({
            key: 'block',
            item: block,
            what,
            value,
            setFn: resolveChange,
            loadingFn: setLoading,
            setBeforePost: true
        });
    };

    const handleSelect = () =>
        onSelect(block);

    const pushRow = (_new: boolean, index?: number) => (row: RowData) => {
        const _block = {rows: [], ...block};
        const _row = {...row, new: _new, index: index}
        if (!index) {
            _block.rows.push(_row);
        } else {
            _block.rows = insertAtIndex(_block.rows, index, _row)
        }
        onChange(_block);
    }

    const createRow = (data) => {
        handleEstimationChange({key: 'row', item: data, setFn: pushRow(true), loadingFn: setAddRowLoading});
    }

    const handleCreateRow = () => {
        createRow({block_id: block.id as number, role_id: context?.roles[0].id, index: block.rows?.length || 0})
    }

    const onRowDelete = (id) => {
        const _block = {...block};
        _block.rows = _block.rows.filter(row => row.id !== id);
        onChange(_block);
    }

    const onRowChange = (row) => {
        //console.log("row has been chanegd",row)
        const foundIndex = block.rows.findIndex(_row => _row.id === row.id);
        block.rows[foundIndex] = row;
        setPrice(blockPrice(block));
    }

    const handleDelete = async () => {
        try{
            setLoading(true);
            await deleteEntity('block', block.id);
            onDelete(block.id);
        }catch (e) {
            if(e.code === 404){
                flashMessage(t('Block not found',{block}));
            }else{
                //flashMessage(t('Unknown error'));
            }
        }finally {
            setLoading(false);
        }
    }

    const handleCloneRow = async (row: RowData) => {
        setLoading(true);
        const idx = block.rows.findIndex(_row => _row === row) + 1;
        const clone = await cloneRow(row, {index: idx});
        pushRow(false, idx)(clone);
        setLoading(false);
    }
    const cloneBlock = () => onClone(block);

    const addToTemplates = async () => {
        try {
            setLoading(true);
            await addBlockToTemplates(block, context.roles);
            onTemplateAdded && onTemplateAdded();
            flashMessage(t('Template created',{...block}),'success');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Paper className={clsx("relative",className)} {...otherProps}
               header={(
                   <div className={"flex flex-col md:flex-row justify-between"}>
                       {env('DEBUG') &&
                           <small className={'absolute left-0 bottom-full whitespace-nowrap  opacity-50 text-gray-400'}>
                               #{block.id} created: {t('dateTime',{date: new Date(block.created)})}, updated: {t('dateTime',{date: new Date(block.updated)})}
                           </small>
                       }
                       <div className={"flex-grow mr-8 order-2 md:order-1"}>
                           <Input
                               ref={firstInputRef}
                               placeholder={t("Blocks name")}
                               className={"w-full flex-grow sm:mr-4 sm:mb-1 input-lg order-2 sm:order-1"}
                               defaultValue={block.name}
                               name={"name"}
                               spellCheck="false"
                               onChange={handleChange}
                           />
                           <Input
                               placeholder={t("Blocks description")}
                               tagName={"textarea"}
                               rows={1}
                               className={"block w-full mt-2 min-h-[2.75em]"}
                               defaultValue={block.description}
                               name={"description"}
                               spellCheck="false"
                               onChange={handleChange}
                           />
                       </div>
                       <div className={"flex flex-row md:flex-col justify-between items-end text-right order-1 md:order-2"}>
                           <div className="flex flex-row gap-2 items-start">
                               {/*<IconButtonGray onClick={handleAddToFavorites} className={"md:ml-auto mr-0 self-start"}><Star/></IconButtonGray>*/}
                               <Button bg={'gray'} color={'gray'} size={false} className={'p-1'} icon={Save} title={t('Make template', {block})} onClick={addToTemplates}/>
                               {onClone && <Button bg={'gray'} color={'gray'} size={false} className={'p-1'} icon={FileCopy} title={t('Duplicate block', {block})} onClick={cloneBlock}/>}
                               <Confirm relative onSuccess={handleDelete} title={t("Delete block", {block})} message={t("Re u sure bro? No way back")}>
                                   <Button bg={'gray'} color={'red'} size={false} className={'p-1'} icon={Delete} title={t("Delete block", {block})} />
                               </Confirm>
                               {onSelect && <Button bg={'gray'} color={'primary'} iconSize={'large'} className={'p-1 text-[3rem]'} size={false} icon={Check} title={t('Select block', {block})} onClick={handleSelect}/>}
                           </div>
                           <div className={"-mb-1"}>
                               <small>{t('Estimated block cost')}:</small><br/>
                               <span className={"text-xl"}>{t('money',{value: price})}</span>
                           </div>
                       </div>
                   </div>
               )}
        >
            {loading && <LoadingSpinner className={"text-primary absolute -left-6 top-6"}/>}

            <Droppable droppableId={block.id.toString()} type={DND_TYPE_ROW}>
                {(droppableProvided, droppableSnapshot) => (
                    <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef} className={clsx(droppableSnapshot.isDraggingOver ? 'bg-opacity-5' : 'bg-opacity-0', 'bg-primary transition-colors -m-4 p-4')}>
                        <TransitionGroup appear={!isDragging} exit={!isDragging} enter={!isDragging}>
                            {block?.rows?.map((row,index) =>
                                <CSSTransition key={row.id} timeout={env('TRANSITION_DURATION')} classNames="transition">
                                    <Draggable key={row.id} draggableId={`${row.id}`} index={index}>
                                        {(draggableProvided, draggableSnapshot) => (
                                            <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} className={"bg-white bg-opacity-75 relative py-1"}>
                                                <DragHandle {...draggableProvided.dragHandleProps} className={'-left-3 -translate-x-full'} />
                                                <EstimationEditorRow key={row.id} row={row} onChange={onRowChange} onClone={handleCloneRow} onDelete={onRowDelete}/>
                                            </div>
                                        )}
                                    </Draggable>
                                </CSSTransition>
                            )}
                        </TransitionGroup>
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
            <Button bg={'primary'} color={'white'} icon={addRowLoading? LoadingSpinner : Add} onClick={handleCreateRow} className={"w-full uppercase justify-center mt-8"}>
                {t('create new row')}
            </Button>
        </Paper>

    );
}

