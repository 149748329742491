import store from "../../components/store";
import {flashesSlice, FlashType, IFlashMessage} from "./flashesSlice";
import {env} from "../helpers";

const timeout = Number(env('FLASH_MESSAGE_TIMEOUT'));

export const add = (message: string, type: FlashType): IFlashMessage => {
    const m: IFlashMessage = {message, type, uid: (new Date()).getTime()};
    store.dispatch(flashesSlice.actions.add(m));
    return m;
};
export const remove = (message: IFlashMessage) => store.dispatch(flashesSlice.actions.remove(message));

export const info = (message: string) => add(message, 'info');
export const error = (message: string) => add(message, 'error');
export const success = (message: string) => add(message, 'success');
export const flashMessage = (message: string, type: FlashType = 'info',time: number = timeout) => {
    const m = add(message, type);
    setTimeout(() => remove(m),time);
}

const flashes = {add, remove, info, error, success, flashMessage};

export default flashes;
