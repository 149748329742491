import React, {useEffect, useRef, useState} from 'react';
import Input from "../ui/form/Input";
import Button from "../ui/form/Button";
import Paper from "../common/Paper";
import {objectAssignExisting} from "../../utils/helpers";
import {insertEntity, updateEntity} from "../../api/estimations";
import {RootStoreState, useTypedSelector} from "../store";
import {flashMessage} from "../../utils/flashes";
import Password from "../ui/form/Password";
import Header from "../layout/Header";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {apiFetch} from "../../api/common";
import UserSettings from "./UserSettings";
import {IUser} from "./userSlice";
import * as securityUser from './index';
import {flashTypes} from "../../utils/flashes/flashesSlice";

type UserEditorState = {
    name?: string,
    email?: string,
    currentPassword?: string,
    password?: string
};

const UserEditor = () => {
    const { t } = useTranslation();
    const { id } = useParams<{id?: string}>();
    const history = useHistory();
    const loggedInUser = useTypedSelector((state: RootStoreState) => state.user);
    const formRef = useRef<HTMLFormElement>();
    const [values, setValues] = useState<UserEditorState>({
        email: '',//email: user?.email,
        name: '',//name: user?.name,
        currentPassword: '',
        password: ''
    });
    const currentUser = loggedInUser.id === Number(id);

    useEffect(()=>{
        if (!id) return;
        apiFetch(`/users/${id}`)
            .then((user: IUser) => setValues(prevValues => objectAssignExisting({...prevValues}, user)))
            .catch(err=>{
                err.status === 404
                    ? history.push('/404')
                    : flashMessage(err?.message, flashTypes.error)
            });
    }, [history, id]);
    useEffect(()=>{
        console.log({values});
    }, [values]);
    const validate = () => {
        if (values.password && !values.currentPassword) {
            flashMessage(t('You have to fill your current password to change it.'), flashTypes.error)
            return false;
        }
        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) return;
        !id
            ? insertEntity<IUser>('user', values as IUser)
                .then(user => {
                    history.push(`/user/${user.id}`)
                    flashMessage(t('User created', {name: user.name}), flashTypes.success);
                })
                .catch(err => flashMessage(err.message, flashTypes.error))
            : updateEntity<any & IUser>('user', {id: id, ...values})
                .then((data: IUser) => {
                    loggedInUser.id === parseInt(id) && securityUser.update(data);
                    //setValues(prevValues => objectAssignExisting({...prevValues}, {...data, password: '', currentPassword: ''}));
                    flashMessage(t('User modified', {id: id}), flashTypes.success);
                    history.push(`/users`)
                }).catch(err => flashMessage(err.message, flashTypes.error));
    };

    const handleChange = (what, value) => {
        setValues({...values, [what]: value});
    }

    return (
        <section className={"max-w-lg mx-auto"}>
            <Header backlink="/users">{id ? t('User editor') : t('Add new user')}</Header>
            {(
                <Paper header={
                    <h1 className={"text-2xl"}>
                        {id ? `${t('User')} "${values.name}" (#${id})` : t(`New user`,{name: values.name})}
                    </h1>
                }>
                    <div>
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <Input key="name" name="name" type="text" placeholder={t('Name')}
                                   className={"w-full px-3 my-2"}
                                   required={true}
                                   autoComplete="name"
                                   defaultValue={values.name}
                                   onChange={handleChange}
                            />
                            <Input key="email" name="email" type="email" placeholder={t('E-mail')}
                                   className={"w-full px-3 my-2"}
                                   required={true}
                                   autoComplete="username"
                                   defaultValue={values.email}
                                   onChange={handleChange}
                            />
                            <div className={"my-2"}>
                                <Password required={!!values.password}
                                          name={'currentPassword'}
                                          placeholder={t('Current password')}
                                          autoComplete={"current-password"}
                                          onChange={handleChange}
                                          defaultValue={values.currentPassword}/>
                            </div>
                            <div className={"my-2"}>
                                <Password required={false}
                                          name={'password'}
                                          placeholder={t('New password')}
                                          autoComplete={"new-password"}
                                          onChange={handleChange}
                                          defaultValue={values.password}/>
                            </div>
                            <Button bg={'primary'} color={'white'} type="submit" className={"w-full"}>
                                {t('Save')}
                            </Button>
                        </form>
                    </div>
                </Paper>
            )}
            {currentUser && <UserSettings/>}
        </section>
    );
};

export default UserEditor;
