import React, {HTMLAttributes, ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {fetchListPaginated} from "src/api/estimations";
import Paper from "../../common/Paper";
import Loading, {LoadingMessages} from "../../common/loading/Loading";
import clsx from "clsx";
import {Sync} from "@material-ui/icons";
import Pagination from "../../ui/Pagination";
import {useTranslation} from "react-i18next";
import Header from "../../layout/Header";
import localStorage from "../../../utils/localStorage";
import Button, {ButtonProps} from "../../ui/form/Button";
import Overlay from "../../ui/Overlay";
import LoadingSpinner from "../loading/LoadingSpinner";
import {isEmpty} from "../../../utils/helpers";
import GridHeader from "./GridHeader";
import ActionElement from "./ActionElement";
import {useHistory} from "react-router-dom";
import {EntityType, ListResponse, SortOrder} from "../../../api/types";
import {flashMessage} from "../../../utils/flashes";

export type SortDirection = 'ASC' | 'DESC';
export type Sorting<E> = Partial<Record<keyof E, SortDirection>>;

export interface GridParams<E> {
    title?: string,
    keyProp: keyof E,
    columns: Column<E>[],
    where?: Partial<E>,
    defaultOrder?: Sorting<E>,
    entity: EntityType,
    actions?: Array<ActionFactory<E>>,
    globalActions?: (redraw: () => void, setLoading: (bool) => void) => ReactElement,
    limits?: Array<[string | number, number]>,
    sortBehaviour?: Record<string, string | undefined>,
    storageKey?: string,
    onClick?: (entity: E) => void,
    link?: (entity: E) => string,
    multiSort?: boolean,
    isSelected?: (entity: E) => boolean
}



export type Column<E> = {
    key: keyof E | null,
    label: string,
    value?: (entity: E, i: number,data: ListResponse<E>) => any,
    search?: () => void,
    className?: string,
    cellProps?: (entity: E, i: number,data: ListResponse<E>) => HTMLAttributes<HTMLTableCellElement>
};

const defaultParams = {
    where: {},
    actions: [],
    globalActions: () => (<></>),
    isSelected: () => false,
    defaultOrder: {},
    limits: [
        [5, 5],
        [20, 20],
        [40, 40],
        [80, 80],
        //['All', null],
    ],
    multiSort: true
}

export type Action = ButtonProps & {
    onAction: () => Promise<boolean>,
    label?: string,
    confirm?: string,
    button?: (any) => JSX.Element,
};
export type ActionFactory<E> = (e: E) => Action;

export function Grid<Entity>(params: GridParams<Entity>) {

    const {
        title,
        where,
        keyProp,
        entity,
        limits,
        columns,
        actions,
        globalActions,
        defaultOrder,
        storageKey,
        onClick,
        isSelected,
        link,
        multiSort
    }
        = {...defaultParams, ...params};

    const {t} = useTranslation();
    const history = useHistory();
    const storageRef = useRef<any>(localStorage.get(storageKey));
    const [data, setData] = useState<ListResponse<Entity>>();
    const [offset, setOffset] = useState<number>(storageRef.current.offset || 0);
    const [limit, setLimit] = useState<number>(storageRef.current.limit || 40);
    const [order, setOrder] = useState<Sorting<Entity>>(storageRef.current.order || {});
    const [search, setSearch] = useState<Partial<Entity>>(storageRef.current.search || {});

    const [loading, setLoading] = useState(false);
    const acRef = useRef<AbortController>(null);

    if (link && onClick)
        throw new Error('User link or onClick not both');

    let _onClick = onClick || (link && (async (e) => {
        history.push(link(e));
        return false;
    }));

    const loadData = useCallback( () => {
        Object.assign(storageRef.current, {limit, order, search, offset});
        setLoading(true);
        if (acRef.current) {
            acRef.current.abort();
            acRef.current = null;
        }
        const {signal} = acRef.current = new AbortController();
        const _order: SortOrder = isEmpty(order) ? defaultOrder : order;
        fetchListPaginated<Entity>(entity, {where, order: _order, search, limit, offset}, {signal})
            .then(setData)
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    console.error(err);
                    flashMessage(t(err.message));
                }
            }).finally(()=>{
                 acRef.current = null;
                 setLoading(false);
            });
    }, [t, storageRef, entity, where, order, search, limit, offset,defaultOrder]);

    useEffect(() =>  loadData(), [loadData, search, order, offset, limit]);
    const _setLimit = (i)=>{
        setLimit(i);
        setOffset(0);
    }
    const hasActions = Boolean(actions.length);
    if (!data) return <Loading children={LoadingMessages.fetching}/>
    return <div className={'relative'}>
        <Overlay visible={loading} className='flex items-start justify-center py-24 z-10'>
            <LoadingSpinner className='text-primary' size='5rem'/>
        </Overlay>
        <Header rightAddon={
            <div className={'flex flex-row gap-1'}>
                {!actions.length && !isEmpty(search) &&
                <Button type={'reset'} color={'red'} icon={Sync} onClick={() => setSearch({})}
                        title={t('Reset Search')}/>}
                {globalActions(loadData, setLoading)}
            </div>
        }>{title}</Header>
        <Pagination offset={offset} limit={limit} count={data.count} onChange={setOffset}/>
        <Paper className={"flex flex-col"}>
            <div className={"overflow-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-300 pb-2 pb-lg-0"}>
                <table className={"relative w-full table-auto whitespace-nowrap"}>
                    <GridHeader columns={columns} actions={hasActions} multiSort={multiSort}
                                search={search} order={order} setSearch={setSearch} setOrder={setOrder}/>
                    <tbody className={"divide-y relative overflow-hidden"}>
                    {data?.data?.map((item: Entity,i) => {
                            const colProps = _onClick ? {onClick: () => _onClick(item)} : {};
                            return <tr key={`${item[keyProp]}`}
                                       className={clsx('focus-within:z-10', _onClick && 'cursor-pointer', isSelected(item) ? 'bg-primary hover:bg-opacity-60' : 'bg-white hover:bg-gray-100 ')}>
                                {columns.map((column) => {
                                        const {key, value, className, cellProps} = column;
                                        const props = cellProps ? cellProps(item, i,data) : {};
                                        return <TableTd key={String(key)} {...colProps} {...props}  className={clsx("text-left", className)}>
                                            {value ? value(item, i,data) : item[key]}
                                        </TableTd>
                                    }
                                )}
                                {hasActions && <TableTd key={'actions'} className={"text-right sticky right-0 bg-white bg-inherit"}>
                                    <div className="flex justify-end gap-2 z-20">
                                        {actions.map((factory, i) => {
                                            const action: Action = factory(item);
                                            return <ActionElement key={i} action={action} onSuccess={loadData}
                                                                  setLoading={setLoading}/>
                                        })}
                                    </div>
                                </TableTd>}
                            </tr>
                        }
                    )}
                    </tbody>
                </table>
            </div>
            {!data && <Loading children={LoadingMessages.fetching}/>}
        </Paper>
        <Pagination offset={offset} limit={limit} count={data.count} onChange={setOffset}/>
        <div className={'sm:flex text-center sm:justify-between text-gray-400 text-sm py-2'}>
            <span>{t('Count')}: {data?.count}</span>
            <div className={"flex gap-2 justify-center sm:justify-end"}>
                <span>{t('Items per page')}:</span>
                {limits.map(([label, i]) =>
                    <Button color={i === limit ? 'gray' : 'primary'} size={false} key={i}
                            disabled={i === limit}
                            className={'cursor-pointer'}
                            onClick={() => _setLimit(i)}>
                        {t(`${label}`)}
                    </Button>
                )}
            </div>
        </div>
    </div>;
}



const TableTd = ({children, className, ...otherProps}: React.HTMLAttributes<any>) =>
    <td {...otherProps} className={clsx("p-2", className)}>{children}</td>;


