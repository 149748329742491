import Modal from "../../ui/Modal";
import {createRevision} from "src/api/estimations";
import React from "react";
import {useHistory} from "react-router-dom";
import {ButtonWithLoading} from "../../ui/form/Button";
import {useTranslation} from "react-i18next";
import {Column, Grid} from "../../common/grid/Grid";
import {Add, TrackChanges} from "@material-ui/icons";
import {EstimationData} from "../../../api/types";
import {flashMessage} from "../../../utils/flashes";

interface RevisionsProps {
    estimation: EstimationData
}


export function EstimationRevisions({estimation}: RevisionsProps) {
    const {t} = useTranslation();
    const history = useHistory();

    const columns: Column<EstimationData>[] = [
        {key: 'name', label: 'Name'},
        {key: 'hash', label: 'hash'},
        {key: 'estimated_price', label: 'Estimated price', value: (e) => t('money',{value: e.estimated_price})},
        {key: 'revision_number', label: 'number'},
        {key: 'revision', label: 'Revision'},
        {
            key: 'updated',
            label: 'Updated',
            value: e => t('dateTime', {date: new Date(e.updated)}),
            cellProps: (e) => ({title: t("dateAgo", {date: new Date(e.updated)})})
        },
        {
            key: 'created',
            label: 'Created',
            value: e => t('dateTime', {date: new Date(e.created)}),
            cellProps: (e) => ({title: t("dateAgo", {date: new Date(e.created)})})
        },
    ];

    const onClick = async () => {
        try {
            const result = await createRevision(estimation.id);
            if (result.hash) {
                history.push(`/${result.hash}`);
            } else {
                flashMessage('Unknown error', 'error');
            }
        } catch (e) {
            flashMessage(e.message, 'error');
        }
    };

    return <>
        <ButtonWithLoading icon={Add} onClick={onClick} bg={'white'} color={'black'} title={t('Create Revision')}>
            {t('Create Revision')}
        </ButtonWithLoading>
        <Modal className={'container mt-12 bg-white p-4 rounded shadow'} enabled={true} button={<>{t('Revisions')}
            <TrackChanges className={"ml-2"}/></>}>
            <Grid<EstimationData>
                where={{parent_id: estimation.parent_id || estimation.id}}
                keyProp='hash' isSelected={(e) => e.id === estimation.id} link={(e) => `/${e.hash}`}
                entity={'estimation'} columns={columns} title={t('Revisions of', {...estimation})}/>
        </Modal>
    </>
}
