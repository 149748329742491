import React from 'react';
import {useTranslation} from "react-i18next";
import {RootStoreState, useTypedSelector} from "../store";
import {signOut} from "../../api/sign";
import Button from "../ui/form/Button";
import {AccountCircle, Edit, List, Menu, People, Settings} from "@material-ui/icons";
import DropDown from "../ui/DropDown";
import {MdDonutLarge} from "react-icons/md";
import Link from "../ui/Link";

const languages = [  // eslint-disable-line @typescript-eslint/no-unused-vars
    {key: 'cs' ,title: 'Čeština',label:'CS'},
    //{key: 'en' ,title: 'English',label:'EN' }
];  // eslint-disable-line @typescript-eslint/no-unused-vars

const Navigation = () => {
    const { t, i18n } = useTranslation(); // eslint-disable-line @typescript-eslint/no-unused-vars
    const onSignOut = async ()=>await signOut();
    const user = useTypedSelector((state: RootStoreState) => state.user);
    return (
        <nav className={"text-white py-2 mb-6 print:mb-0"}>  {/*bg-gradient-to-r from-primary to-secondary shadow-lg */}
            <div className={"px-6"}> {/*container*/}
                <div className={"flex items-center print:text-primary"}>
                    <div className={"flex items-center relative focus-within:outline-black"}>
                        <div className={"relative mr-2"}>
                            <b className={"absolute  top-1/2 left-1/2 text-[1.25rem] transform -translate-y-1/2 -translate-x-1/2"}>C</b>
                            <MdDonutLarge className={"text-[3rem] animate-spin-slow"}/>
                        </div>
                        <div className={"text-center hidden sm:block print:block"}>
                            <h1 className={"text-2xl uppercase tracking-[0.0275em]"}>Costimation</h1>
                            <h2 className={"uppercase text-xs"}>{t('Tool for cost estimation')}</h2>
                        </div>
                        <Link className={'stretched-link'} to={"/"}/>
                    </div>
                    {/*
                     <div className='mx-5 print:hidden'>
                        {languages.map(({key,label,title},index) => (
                            <Button key={key}  title={title}
                                    className={clsx('inline-flex','p-2',i18n.language === key ? 'font-bold' : 'opacity-50')}
                                    type="submit" onClick={() => i18n.changeLanguage(key)}>
                                {label}
                            </Button>
                        ))}
                    </div>
                    */}
                    {user?.isLoggedIn && (
                        <div className={"flex print:hidden items-center text-gray-500 ml-auto bg-gray-100 px-2 rounded -m-1"}>
                            <div className={"text-sm text-right leading-tight mr-3"}>
                                {user.data.name}<br/>
                                <Button size={false} color={'primary'} type={"button"} onClick={onSignOut} className={"inline-flex"}>{t('Sign out')}</Button>
                            </div>
                            <Link to={`/user/${user.data.id}`} className="group relative mr-2 leading-none text-[3rem] focus:outline-black">
                                <AccountCircle fontSize={'inherit'} />
                                <Edit className={"group-hover:opacity-50 transform transition-all absolute opacity-0 top-0 left-1/2 translate-x-1 group-hover:translate-x-2 text-gray-800 rotate-25  origin-bottom-left  blur-[1px] "}/>
                                <Edit className={"group-hover:opacity-100 transform transition-all absolute opacity-0 top-0 left-1/2  translate-x-1 group-hover:-translate-y-1 text-primary"}/>
                            </Link>
                            <DropDown buttonProps={{icon: Menu, bg: 'white', color: 'black'}}>
                                <div className="flex flex-col">
                                    <NavigationLink to={"/"} label={t('Estimations')} icon={<List/>}/>
                                    <NavigationLink to={"/users"} label={t('Users')} icon={<People/>}/>
                                    <NavigationLink to={"/settings"} label={t('Settings')} icon={<Settings/>}/>
                                </div>
                            </DropDown>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
}

const NavigationLink = ({to, label, icon}) => {
    return (
        <Link to={to} className={"flex justify-between px-4 py-1 hover:bg-gray-200"}>
            {label}
            <span className={"ml-8 text-gray-300"}>{icon}</span>
        </Link>
    );
}


export default Navigation;
