import React from "react";
import {useTranslation} from "react-i18next";
import {rowPrice} from "../../../utils/priceHelpers";
import {EstimationMetrics} from "../private/EstimationEditorRow";

export default function EstimationViewRow({row}) {
    const {t} = useTranslation();
    return (
        <div className={"grid grid-cols-12 gap-x-4 mb-2 print:mb-0 hover:bg-gray-100 transition-colors"}>
            <div className={"col-span-full md:col-span-6 print:col-span-6"}>{row.name}</div>
            <div className={"col-span-full md:col-span-2 print:col-span-2"}>{row.amount}&nbsp;{t(`estimation.row.metrics.${row.metrics}_interval`,{postProcess: 'interval',count: row.amount})}</div>
            <div className={"col-span-full md:col-span-2 print:col-span-2"}>{row.metrics === EstimationMetrics.hours ? row.role?.name : t('money',{value: row.price_per_unit}) }</div>
            <div className={"col-span-full md:col-span-2 print:col-span-2 text-right"}>{t('money',{value: rowPrice(row)})}</div>
        </div>
    );
}
