import React, {useState} from "react";
import Button, {ButtonProps} from "./form/Button";
import clsx from "clsx";
import {env} from "../../utils/helpers";
import {CSSTransition} from "react-transition-group";

interface ModalProps extends React.InputHTMLAttributes<HTMLInputElement> {
    buttonProps?: ButtonProps,
    button?: string |  JSX.Element,
    enabled?: boolean
}

export default function Modal(props: ModalProps) {
    const {children, button, title, enabled, className, buttonProps} = props;
    const [opened, setOpened] = useState<boolean>(false);
    const open = () => {
        setOpened(true);
        document.body.classList.add('overflow-hidden');
    };
    const close = () => {
        setOpened(false);
        document.body.classList.remove('overflow-hidden');
    };
    const toggleModal = () => opened ? close() : open();
    return <>
        <Button disabled={!enabled} bg={'white'} color={'black'} className={'shadow'}
                onClick={enabled ? toggleModal : () => null} type="button" aria-expanded={opened}  {...buttonProps}>
            {button}
        </Button>
        <CSSTransition in={opened} unmountOnExit timeout={env('TRANSITION_DURATION')} classNames="fade-in">
            <div role="dialog" className={clsx(`z-30 fixed right-0 top-0 left-0 bottom-0 overflow-auto p-5 `)}>
                <div onClick={close}
                     className="fixed right-0 top-0 left-0 bottom-0 cursor-pointer bg-black bg-opacity-50"/>
                <div className={clsx(className, 'relative')}>
                    {title && <>{title}
                        <hr/>
                    </>}
                    {children}
                </div>
            </div>
        </CSSTransition>
    </>
}
