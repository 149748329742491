import {postChange} from "../../../api/estimations";
import {env, objectsAreEqual} from "../../../utils/helpers";
import debounce from 'lodash.debounce';
import {EntityData, EntityType} from "../../../api/types";
import {flashMessage} from "../../../utils/flashes";

interface handleEstimationChangeParams<E extends EntityData> {
    key: EntityType,
    item?: E,
    what?: string,
    value?: any,
    setBeforePost?: boolean,
    setFn?: Function,
    loadingFn?: Function
}

const debounceStack = {};

const handleEstimationChange = <E extends EntityData>(
    {key, item, what, value, setFn, loadingFn, setBeforePost}: handleEstimationChangeParams<E>
) => {

    const handleChangeWithApi = (key, _item) => {
        isFn(loadingFn) && loadingFn(true);
        postChange<E>(key, _item)
            .then(responseItem => {
                loadingFn(false);
                return !objectsAreEqual(responseItem, item) && setFn(responseItem);
            })
            .catch((err)=>{
                flashMessage(  err.message,'error');
                loadingFn(false);
            });
        delete debounceStack[key + item.id];
    }

    if (item.id && !debounceStack[key + item.id]) {
        debounceStack[key + item.id] = debounce(handleChangeWithApi, env('UPDATE_DEBOUNCE_DELAY'));
    }

    const _item = item ? {...item} : {};
    if (item && what) {
        if (item[what] === value) return;
        _item[what] = value;
    }

    setBeforePost && isFn(setFn) && setFn(_item);
    item.id
        ? debounceStack[key + item.id](key, _item)
        : handleChangeWithApi(key, _item);
};

const isFn = (fn): boolean =>
    fn && typeof fn === "function";

export default handleEstimationChange;
