import React from 'react';
import Paper from "../common/Paper";
import {useTranslation} from "react-i18next";
import RolesList from "./RolesList";
import {fetchRoles} from "../../api/roles";

const Settings = () => {
    const {t} = useTranslation();
    return (
        <Paper className={"max-w-lg mx-auto"} header={<h1 className={"text-2xl"}>{t('settings')}</h1>}>
            <div className={"flex justify-between"}>
                Název
                <span>{process.env.REACT_APP_NAME}</span>
            </div>
            <div className={"flex justify-between"}>
                Verze
                <span>{process.env.REACT_APP_VERSION}</span>
            </div>
            <hr className={'my-5'}/>
            <h2>{t('Default Roles')}</h2>
            <RolesList roles={async () => fetchRoles(null)}/>

        </Paper>
    );
};

export default Settings;
